import { toast } from 'react-toastify'

export function PostData(type, userData) {
  let online = navigator.onLine ? 'online' : 'offline'
  // console.log(
  //   online
  // );
  let BaseUrl = 'https://portalapi.lifeworthhmo.com/api/Login/'

  return new Promise((resolve, reject) => {
    if (online === 'online') {
      fetch(BaseUrl + type, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())

        .then((responseJson) => {
          resolve(responseJson)
        })
        .catch((error) => {
          console.log(error)

          reject(error)
        })
    } else {
      alert('You are Disconnected From The Internet')
    }
  })
}

export const BASE_URL = 'https://portalapi.lifeworthhmo.com/api'
