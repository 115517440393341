import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import "./list.css";
import toast, { Toaster } from "react-hot-toast";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Navbar from "../../sidebar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  autoComplete,
  handleMonthChange,
  handleSelectedProvider,
  handleYearChange,
} from "../../functions/AutoComplete";
import { calendarMonths } from "../../months/Months";
import { ReloadContext } from "../../context/reviewContext/ReviewInitialState";

export default function ViewList() {
  let navigate = useHistory();
  const [claims, setClaims] = useState([]);

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [loadingClaimsList, setLoadingClaimsList] = useState(false);
  const Info = sessionStorage.getItem("fullName");
  const [claimsIndex, setClaimsIndex] = useState(0);
  const [review, setReview] = useState(false);
  const [reviewItem, setReviewItem] = useState(null);
  const [reviewInfo, setReviewInfo] = useState(null);

  const [updatedReviewInfo, setUpdatedReviewInfo] = useState({});
  const [filterList, setFilterList] = useState({
    providerList: false,
    monthList: false,
  });
  const { reload, newAmount, dispatch } = useContext(ReloadContext);

  const [selectedFilterParams, setSelectedFilterParams] = useState({
    selectedProvider: "",
    selectedMonth: "",
  });
  const [filteredMonths, setFilteredMonths] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  let filtered;
  let monthId;

  const provideName = sessionStorage.getItem("ProviderName");
  const selectedId = sessionStorage.getItem("providerId");
  const [reloadPage, setReloadPage] = useState(false);

  const datePicker = (e) => {
    const cancelToken = axios.CancelToken.source();
    // const date = new Date(e.target.value);
    // const year = date.getFullYear();
    // setYear(year);
    // const month = date.getMonth() + 1;
    // const nameOfMonth = date.toLocaleString("default", { month: "long" });
    // setMonth(nameOfMonth);
    if (selectedMonth && year) {
      monthId = calendarMonths.find(
        (singleCalendarMonth) => singleCalendarMonth.name === selectedMonth
      );
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${selectedId}&role=approver&month=${month}&year=${year}&pageSize=50&pageNumber=1`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          // console.log(response.data)
          setClaims(response.data);
        })
        .catch((error) => console.error(error));
    }
    return () => {
      cancelToken.cancel();
    };
  };

  const ClaimsList = claims
    .flatMap((data) => data.claims)
    .filter((data) => data.initiatedBy !== null)
    .map((data) => data.chargesSent);
  const approvedList = claims
    .flatMap((data) => data.claims)
    .filter((data) => data.initiatedBy !== null)
    .map((data) => data.chargesApproved);
  // const approvedClaims = claims.flatMap((data) => data.claims);
  const approvedClaims = claims[claimsIndex]?.claims.flatMap((data) => {
    return data;
  });

  const providerBill = ClaimsList.reduce((a, b) => a + b, 0);
  const approvedBill = approvedList.reduce((a, b) => a + b, 0);

  const providerSelect = useCallback(() => {
    const cancelToken = axios.CancelToken.source();
    setLoadingClaimsList(true);

    if (selectedId) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=approver&pageNumber=1&pageSize=50&claimType=plaschema`,
          { cancelToken: cancelToken.token }
        )
        .then((response) => {
          setLoadingClaimsList(false);
          setClaims(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => {
      cancelToken.cancel();
    };
  }, [selectedId]);

  const fetchList = () => {
    const cancelToken = axios.CancelToken.source();
    axios
      .get(
        `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=approver&pageNumber=1&pageSize=50&claimType=plaschema`,
        { cancelToken: cancelToken.token }
      )
      .then((response) => {
        setClaims(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    return () => {
      cancelToken.cancel();
    };
  };

  const setEntity = () => {
    let data = [];
    let Claim = [];
    Claim.detail = approvedClaims;

    Claim.detail.forEach((element) => {
      let dataInfo = {
        approvedBy: Info,
        sno: element.sno,
      };
      data.push(dataInfo);
    });

    let answer = window.confirm("You are about to approve This Claim");
    if (data) {
      if (answer) {
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/approveclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast("Approved Succesfully", {
              style: {
                borderRadius: "10px",
                background: "#F8A370",
                color: "#fff",
              },
            });
          })
          .then(() => {
            fetchList();
          })
          .then(() => window.location.reload())
          .catch((error) => console.error(error));
      }
    }
  };

  const reviewData = (data) => {
    setReview(true);
    setReviewInfo(data);
    setUpdatedReviewInfo(data);
    setReviewItem(data);
    let {
      sno,
      authdate,
      description,
      classification,
      diagnosis,
      employeeName,
      employeeNo,
      authorcode,
      consultancyDate,
      qty,
      chargesSent,
      month,
      type,
      protype,
      year,
      day,
    } = data;
    sessionStorage.setItem("ID", sno);
    sessionStorage.setItem("authdate", authdate);
    sessionStorage.setItem("Description", description);
    sessionStorage.setItem("classification", classification);
    sessionStorage.setItem("diagnosis", diagnosis);
    sessionStorage.setItem("employeeName", employeeName);
    sessionStorage.setItem("employeeNo", employeeNo);
    sessionStorage.setItem("authorcode", authorcode);
    sessionStorage.setItem("consultancyDate", consultancyDate);
    sessionStorage.setItem("qty", qty);
    sessionStorage.setItem("chargesSent", chargesSent);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("day", day);
    sessionStorage.setItem("year", year);
    sessionStorage.setItem("protype", protype);
    sessionStorage.setItem("type", type);
  };

  const handleSubmitReview = () => {
    let answer = window.confirm("You have are about to update a claim");

    if (updatedReviewInfo) {
      if (answer) {
        axios
          .put(
            `https://portalapi.lifeworthhmo.com/api/Claims/${reviewInfo?.sno}`,
            { ...updatedReviewInfo, claimType: "plaschema" }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast("Reviewed Succesfully", {
              style: {
                borderRadius: "10px",
                background: "#F8A370",
                color: "#fff",
              },
            });
          })
          .then(() => {
            setReview(false);
            fetchList();
          })
          .then(() => {
            dispatch({
              type: "RELOAD",
              payload: reloadPage,
            });
          })
          .catch((error) => console.error(error));
      }
    }
  };

  // const verifiedList1 = claims.filter((data)=>data.verifiedBy === null)
  // console.log(verifiedList1);

  // const verifiedList2 = claims.filter((data)=>data.verifiedBy !== null)

  // const amount = name === Info && verifiedList1 ? verifiedList1.map((data)=>data.chargesApproved) : verifiedList2.map((data)=>data.chargesApproved)
  // const amountPaid = amount? amount.reduce((a,b)=>a+b ,0) : ""
  // console.log(amountPaid);

  useEffect(() => {
    providerSelect();
  }, [providerSelect]);

  useEffect(() => {
    // handleSetClaimsIndex()
  }, [claimsIndex]);

  useEffect(() => {
    datePicker();
  }, [selectedMonth, year]);

  const backClick = () => {
    // navigate.push('./dash')
    window.history.back();
  };

  const handleSetClaimsIndex = (action) => {
    if (action === "prev" && claimsIndex > 0) {
      setClaimsIndex(claimsIndex - 1);
    }
    if (action === "next" && claimsIndex < claims.length - 1) {
      setClaimsIndex(claimsIndex + 1);
    }
  };

  const handleSetCharges = (e, type) => {
    if (type === "quantity") {
      // setReviewedQuantity(e.target.value)
      setUpdatedReviewInfo({
        ...updatedReviewInfo,
        approvedQty: Number(e.target.value),
      });
    }
    if (type === "charges") {
      // setReviewedPrice(e.target.value)
      setUpdatedReviewInfo({
        ...updatedReviewInfo,
        chargesApproved: Number(e.target.value),
      });
    }
  };

  const handleSetReview = () => {
    setReview(false);
  };

  return (
    <>
      <div className="viewClaimsWrapper">
        {/* <Navbar /> */}
        <div className="viewClaimsRight">
          {/* <div className='claimsFilterWrapper'> */}
          {/* <div onClick={backClick} className='backToPending'>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <p className='claimAmount'>
              {' '}
              {claims?.length > 0
                ? `Claims  ${claimsIndex + 1}  of ${claims.length}`
                : 'No cliams available'}
            </p> */}

          {/* <div className="monthsFilterWrapper">
              <div className="monthsFilter">
                <input
                  onClick={() =>
                    autoComplete("month", setFilterList, filterList)
                  }
                  placeholder="Month"
                  type="text"
                  className="monthsFilterInput"
                  onChange={(e) =>
                    handleMonthChange(
                      e,
                      filtered,
                      calendarMonths,
                      setFilteredMonths,
                      setSelectedMonth,
                      setFilterList,
                      filterList
                    )
                  }
                  value={selectedMonth}
                />
              </div>
              {filterList.monthList && (
                <div className="monthsList">
                  {
                    filteredMonths.length > 0 ? (
                      filteredMonths.map((singleMonth, index) => {
                        return (
                          <div
                            className="monthsListItem"
                            key={index}
                            onClick={(e) =>
                              handleSelectedProvider(
                                "month",
                                e,
                                setSelectedMonth,
                                setMonth,
                                filteredMonths,
                                setSelectedFilterParams,
                                selectedFilterParams,
                                setFilterList
                              )
                            }
                          >
                            {singleMonth.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="inputMonthName">Input month</div>
                    )
                    // {calen}
                  }
                </div>
              )}
            </div>
            <div className="yearInputWrapper">
              <input
                type="number"
                className="yearInput"
                placeholder="year"
                value={year ? year : null}
                onChange={(e) => handleYearChange(e, setYear)}
              />
            </div>
            <div
              className="showAll"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear filter
            </div> */}
          {/* <div className='navBtnWrapper'>
              <div
                className='previousClaim'
                onClick={() => handleSetClaimsIndex('prev')}
              >
                Previous
              </div>
              <div
                className='nextClaim'
                onClick={() => handleSetClaimsIndex('next')}
              >
                Next
              </div>
            </div> */}
          {/* </div> */}
          <div className="styledTableWrapper">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Treatment Items</th>
                  <th>Submitted Qty</th>
                  <th>Submitted Bill</th>
                  <th>Payable Qty</th>
                  <th>Payable Bill</th>
                  <th>Initiated By</th>
                  <th>Verified By</th>
                  <th>Review</th>
                </tr>
              </thead>
              <tbody>
                {claims.length === 0 && !loadingClaimsList && (
                  <div className="noClaims">No claims available</div>
                )}
                {loadingClaimsList && (
                  <div className="noClaims">Loading claims...</div>
                )}
                {claims[claimsIndex]?.claims.map((claim) => {
                  return (
                    <tr>
                      <td>{claim.diagnosis}</td>
                      <td>{claim.qty}</td>
                      <td>{claim.chargesSent}</td>
                      {/* <td>{claim.approvedQty ? claim.approvedQty : 0}</td>
                      <td>{claim.chargesApproved}</td>
                      <td>{claim.initiatedBy}</td> */}

                      <td>
                        {review ? (
                          reviewItem?.sno === claim.sno ? (
                            <input
                              type="number"
                              onChange={(e) => handleSetCharges(e, "quantity")}
                              className="reviewInput"
                              min={0}
                            />
                          ) : (
                            claim.approvedQty
                          )
                        ) : (
                          claim.approvedQty
                        )}
                      </td>
                      {/* <td>{claim.chargesApproved}</td> */}
                      <td>
                        {review ? (
                          reviewItem?.sno === claim.sno ? (
                            <input
                              type="number"
                              onChange={(e) => handleSetCharges(e, "charges")}
                              className="reviewInput"
                              min={0}
                            />
                          ) : (
                            claim.chargesApproved
                          )
                        ) : (
                          claim.chargesApproved
                        )}
                      </td>
                      <td>{claim.initiatedBy}</td>
                      <td
                        style={{ textTransform: "capitalize" }}
                        className="verified"
                      >
                        {claim.verifiedBy.toLowerCase()}
                      </td>

                      <td>
                        {/* <Link to='/adjustclaims'> */}
                        {review ? (
                          reviewItem?.sno === claim.sno ? (
                            <div className="reviewBtns">
                              <div
                                className="cancelReview"
                                onClick={handleSetReview}
                              >
                                Cancel
                              </div>
                              <div
                                className="approveReview"
                                onClick={handleSubmitReview}
                              >
                                Done
                              </div>
                            </div>
                          ) : (
                            <button
                              className="claims-btn-review"
                              onClick={() => reviewData(claim)}
                            >
                              Review
                            </button>
                          )
                        ) : (
                          <button
                            className="claims-btn-review"
                            onClick={() => reviewData(claim)}
                          >
                            Review
                          </button>
                        )}

                        {/* </Link> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Patient ID</th>
                <th>Name</th>
                <th>Diagnosis</th>
                <th>Qty Submitted</th>
                <th>Provider Bill</th>
                <th> Total Provider Amount</th>
                <th>Qty Approved</th>
                <th>Approved Amount</th>
                <th> Total Approved Amount</th>
                <th>Initiated By</th>
                <th>Verified By</th>
                <th>Compliance</th>
              </tr>
            </thead>
            {claims.map((data, index) => (
              <tbody className="">
                <tr key={index}>
                  <td>{data.claims[0].consultancyDate.substring(0, 10)}</td>
                  <td>{data.employeeNumber}</td>
                  <td>{data.employeeName}</td>
                  <td>
                    {data.claims.map((data) => (
                      <h6>
                        {data.diagnosis}
                        <br />
                      </h6>
                    ))}
                  </td>
                  <td>
                    {data.claims.map((data) => (
                      <h4>
                        {data.qty}
                        <br />
                      </h4>
                    ))}
                  </td>
                  <td>
                    {data.claims.map((data) => (
                      <h4>
                        {data.chargesSent}
                        <br />
                      </h4>
                    ))}
                  </td>
                  <td>
                    <h4>
                      {data.claims
                        .map((data) => data.chargesSent)
                        .reduce((a, b) => a + b, 0)}
                    </h4>
                  </td>
                  <td>
                    {data.claims.map((data) => (
                      <h4>
                        {data.approvedQty}
                        <br />
                      </h4>
                    ))}
                  </td>
                  <td>
                    {data.claims.map((data) => (
                      <h4>
                        {data.chargesApproved}
                        <br />
                      </h4>
                    ))}
                  </td>
                  <td>
                    <h4>
                      {data.claims
                        .map((data) => data.chargesApproved)
                        .reduce((a, b) => a + b, 0)}
                    </h4>
                  </td>
                  <td>
                    {data.claims.map((data) => (
                      <h5>
                        {data.initiatedBy}
                        <br />
                      </h5>
                    ))}
                  </td>
                  <td>
                    {data.claims.map((data) => (
                      <h6>
                        {data.verifiedBy}
                        <br />
                      </h6>
                    ))}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            ))}
          </table> */}
            {/* <div className="total-due">
              <h2 className="total-amt">
                Total Provider Bill:{" "}
                <NumberFormat
                  value={providerBill}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
              <h2 className="total-amt">
                Total Approved Amount:{" "}
                <NumberFormat
                  value={approvedBill}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
            </div> */}
          </div>
          <div className="total-due">
            <div className="totalWrapper">
              <h2 className="total-amt-list">
                Total Provider Bill:{" "}
                <NumberFormat
                  value={providerBill}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
              <h2 className="total-amt-list">
                Total Approved Amount:{" "}
                <NumberFormat
                  value={approvedBill}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
            </div>
            <button
              className="approve-btn-list"
              onClick={setEntity}
              disabled={!claims.length > 0}
            >
              Approve
            </button>
          </div>
        </div>
        {/* <button onClick={backClick} className="bck">
          Back to Dashboard
        </button>
        <div className="date-dropdown">
          <label className="label"> Filter by Date:</label>
          <input type="Date" className="date" onSelect={datePicker}></input>
          <Toaster position="top-center" reverseOrder={false} />
          <label className="label">Provider List </label>
          <input className="dropdown" value={provideName} disabled></input>
        </div>
        <div className="bills-approval">
          <div>
            <h2>
              PAYEE:<span className="detail-txt"> {provideName}</span>
            </h2>

            <h2>
              PERIOD:<span className="detail-txt">{`${month}, ${year}`}</span>
            </h2>
          </div>
          <button className="approve-btn" onClick={setEntity}>
            Approve
          </button>
        </div> */}
      </div>
    </>
  );
}
