import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ConfirmationDialogRaw = (props) => {
  const {
    onClose,
    value: valueProp,
    open,
    data,
    setClickedOk,
    providerSelect,
    setReloadClaimsListForInitiator,
    ...other
  } = props;
  const [value, setValue] = React.useState(valueProp);
  const [values, setValues] = React.useState(false);

  const [updatedQuantity, setUpdatedQuantity] = useState(data?.approvedQty);
  const [updatedBill, setUpdatedBill] = useState(data?.chargesApproved);
  const [comment, setComment] = useState(data?.comment);
  const [defaultQuantity, setDefaultQuantity] = useState(data?.approvedQty);
  const [defaultBill, setDefaultBill] = useState(data?.chargesApproved);
  // const [comment, setComment] = useState(data?.comment)
  sessionStorage.setItem("clickedOk", false);
  const radioGroupRef = React.useRef(null);

  // DIALOGUE FUNCTIONS
  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const handleCancel = () => {
    setValues((prev) => !prev);

    setUpdatedQuantity("");
    setUpdatedBill("");
    setComment("");

    onClose();
  };

  // END OF DIALOGUE FUNCTIONS

  // FUNCTION THAT SENDS THE UPDATE REQUEST TO THE BACKEND
  const handleOk = () => {
    if (!updatedQuantity || !updatedBill) {
      toast.info("Kindly fill the required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      let updatedData = {
        ...data,
        approvedBy: sessionStorage.getItem("fullName"),
        qty: data?.qty,
        approvedQty: updatedQuantity,
        sno: data?.sno,
        chargesApproved: updatedBill,
        comment: comment || data?.comment,
      };

      let answer = window.confirm("You are about to review This Claim");

      if (answer) {
        setUpdatedQuantity("");
        setUpdatedBill("");
        setComment("");
        setReloadClaimsListForInitiator((prev) => !prev);
        sessionStorage.setItem("clickedOk", true);
        const id = toast.loading("Updating Claim...");
        axios
          .put(
            `https://portalapi.lifeworthhmo.com/api/Claims/${data?.sno}`,
            updatedData,
            {
              headers: { "Content-Type": "application/json", accept: " */*" },
            }
          )
          .then(async () => {
            toast.update(id, {
              render: "Claim Successfully Updated",
              type: "success",
              autoClose: 2000,
              closeButton: true,
              isLoading: false,
            });

            onClose(value);
            setClickedOk && setClickedOk(true);
            await providerSelect();
          })
          .catch((error) =>
            toast.update(id, {
              render: "Could not update claim, Please try again",
              type: "error",
              autoClose: 2000,
              closeButton: true,
              isLoading: false,
            })
          );
      }
    }
  };

  // FUNCTION THAT HANDLES THE CHANGE OF DATA UPDATE
  const handleSetUpdatedClaimData = (e, dataToBeUpdated) => {
    if (dataToBeUpdated === "quantity") {
      setUpdatedQuantity(e.target.valueAsNumber);
    }
    if (dataToBeUpdated === "bill") {
      setUpdatedBill(e.target.valueAsNumber);
    }
    if (dataToBeUpdated === "comment") {
      setComment(e.target.value);
    }
  };

  // USE EFFECT FOR KEEPING THE UPDATED PARAMS UP TO DATE
  useEffect(() => {
    sessionStorage.setItem("updatedBill", updatedBill);
    sessionStorage.setItem("updatedQuantity", updatedQuantity);
    sessionStorage.setItem("comment", comment);
  }, [updatedBill, updatedQuantity, comment]);

  // USE EFFECT FOR UPDATING THE DATA PROP, SINCE IT IS NULL AT THE BEGINNING

  useEffect(() => {
    setDefaultQuantity(props.data?.qty);
  }, [props]);

  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: 900, maxHeight: 435 } }}
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
      >
        <DialogTitle style={{ fontSize: "18px", height: "40px" }}>
          Update Claim
        </DialogTitle>
        <DialogTitle style={{ fontSize: "16px", height: "50px" }}>
          Unit Price: #{Math.round(data?.chargesSent / data?.qty)}
        </DialogTitle>

        <DialogContent dividers>
          <div style={{ marginBottom: "50px" }}>
            <TextField
              id="outlined-read-only-input"
              label="Submitted Bill"
              // defaultValue={defaultQuantity === undefined ? 1 : defaultQuantity}
              type="number"
              InputProps={{
                readOnly: true,
              }}
              sx={{ marginRight: "30px" }}
              value={data?.chargesSent}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-read-only-input2"
              label="Submitted Quantity"
              value={data?.qty}
              type="number"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <TextField
            id="outlined-read-only-input3"
            label="Updated Bill"
            value={updatedBill}
            type="number"
            placeholder={data?.chargesApproved}
            sx={{ marginRight: "30px" }}
            onChange={(event) => handleSetUpdatedClaimData(event, "bill")}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="outlined-helperText"
            label="Updated Quantity"
            value={updatedQuantity}
            placeholder={data?.approvedQty}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => handleSetUpdatedClaimData(event, "quantity")}
            key={values}
          />
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Comment"
            // key={values}
            style={{
              width: "100%",
              marginTop: 30,
              padding: 10,
              outline: "none",
            }}
            onChange={(event) => handleSetUpdatedClaimData(event, "comment")}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialogRaw;
