import React, { useState } from "react";
import "./invoice.css";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

const Invoice = (props) => {
  // PAGE DATA
  const [data, setData] = useState();

  // CHARGES
  const [chargesApproved, setChargesApproved] = useState(0);
  const [chargesSent, setChargesSent] = useState(0);

  // Access the query parameters from props.location.search
  const params = new URLSearchParams(props.location.search);
  const claimType = params.get("claimType");
  const role = params.get("role");
  const month = params.get("month");
  const year = params.get("year");
  const IdProvider = params.get("IdProvider");
  const providerName = params.get("providerName");

  // FUNCTION TO HANDLE INVOCE DOWNLOAD
  const handleDownloadInvoiceBtnClick = () => {
    window.print();
  }; // END OF FUNCTION TO HANDLE INVOCE DOWNLOAD

  // MONTHS ARRAY
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    // FORMAT CURRENCIES
    const formatCurrency = (amount, currencyCode) => {
      return new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: currencyCode,
      }).format(amount);
    };

    // FUNCTION TO SUM CHARGES
    const sumCharges = (arr, chargesType) => {
      return arr.reduce((acc, curr) => {
        const sum = curr.cliams.reduce(
          (total, claim) => total + claim[chargesType],
          0
        );
        return acc + sum;
      }, 0);
    };

    // FUNCTION TO GET AND SET THE DATA PROP
    const getAndSetData = (currentData) => {
      const cancelToken = axios.CancelToken.source();

      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/bill?claimType=${claimType}&role=accountant&month=${month}&year=${year}&IdProvider=${IdProvider}`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          setData(response.data);
          const sumChargesApproved = sumCharges(
            response.data,
            "chargesApproved"
          );
          const sumChargesSent = sumCharges(response.data, "chargesSent");

          setChargesApproved(formatCurrency(sumChargesApproved, "NGN"));
          setChargesSent(formatCurrency(sumChargesSent, "NGN"));
          // setChargesApproved(sumChargesApproved.toFixed(3));
          // setChargesSent(sumChargesSent.toFixed(3));
        })
        .catch((error) => {
          console.log(error);
        });

      return () => {
        cancelToken.cancel();
      };
    };

    getAndSetData();
  }, []);

  useEffect(() => {}, [chargesApproved, chargesSent]);

  {
    /* <button
        className="downloadInvoiceBtn"
        onClick={handleDownloadInvoiceBtnClick}
      >
        Download
      </button> */
  }

  return (
    <>
      <button
        className="downloadInvoiceBtn"
        onClick={handleDownloadInvoiceBtnClick}
      >
        Download
      </button>
      <div className="invoiceDownloadPageWrapper">
        <table className="invoiceDownloadPageTable">
          <thead className="invoiceDownloadPageTableHead">
            <tr>
              <th colspan="11" className="lifeworthInvoiceTitle">
                LIFEWORTH HMO
              </th>
            </tr>
            <tr>
              <th colspan="11" className="lifeworthInvoiceAddress">
                175 Ikorodu Road, Palmgrove Lagos.
              </th>
            </tr>
            <tr>
              <th colspan="11" className="voucherTitle">
                Payment Voucher for {providerName} ({claimType?.toUpperCase()}{" "}
                Claims) for {monthNames[Number(month) - 1]},{year}
              </th>
            </tr>

            <tr>
              <th>S/N</th>
              <th>Date</th>
              <th>Patient Id</th>
              <th>Name</th>
              <th>Diagnosis</th>
              <th>Treatment Items</th>
              <th>Submitted Qty</th>
              <th>Submitted Bill</th>
              <th>Approved Qty</th>
              <th>Approved Bill</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody className="accountantTableBody">
            {data?.map((singleData, index) => {
              const { employeeName, diagnosis, treatmentDate } =
                singleData?.cliams[0];

              return (
                <>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {treatmentDate.slice(0, 10).split("-")[2] +
                        "-" +
                        new Date(treatmentDate)
                          .toLocaleString("default", {
                            month: "long",
                          })
                          .slice(0, 3) +
                        "-" +
                        treatmentDate.slice(0, 10).split("-")[0]}
                    </td>
                    <td>{singleData.employeeNo}</td>
                    <td>{employeeName}</td>
                    <td>{diagnosis}</td>
                    <td>
                      {singleData.cliams.map((treatmentItem) => {
                        return (
                          <tr>
                            <td>{treatmentItem.description}</td>
                          </tr>
                        );
                      })}
                    </td>
                    <td>
                      {singleData.cliams.map((treatmentItem) => {
                        return (
                          <tr>
                            <td>{treatmentItem.qty}</td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                  <td className='none' style={{ visibility: 'hidden' }}>
                    None
                  </td>
                </tr> */}
                    </td>
                    <td>
                      {singleData.cliams.map((treatmentItem) => {
                        return (
                          <tr>
                            <td>{treatmentItem.chargesSent}</td>
                            <br />
                          </tr>
                        );
                      })}
                      {/* <tr>
                  <td className='total'>
                    {singleData.cliams
                      .map((item) => item.chargesSent)
                      .reduce((a, b) => a + b, 0)}
                  </td>
                </tr> */}
                    </td>
                    <td>
                      {singleData.cliams.map((treatmentItem) => {
                        return (
                          <tr>
                            <td>{treatmentItem.approvedQty}</td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                  <td className='none' style={{ visibility: 'hidden' }}>
                    None
                  </td>
                </tr> */}
                    </td>
                    <td>
                      {singleData.cliams.map((treatmentItem) => {
                        return (
                          <tr>
                            <td>{treatmentItem.chargesApproved}</td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                  <td className='none' style={{ visibility: 'hidden' }}>
                    None
                  </td>
                </tr> */}
                    </td>
                    <td>
                      {singleData.cliams.map((treatmentItem) => {
                        return (
                          <tr>
                            <td className="comment">
                              {treatmentItem.comment ? (
                                treatmentItem.comment
                              ) : (
                                <div style={{ visibility: "hidden" }}>
                                  'No comment'
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                  <td className='none' style={{ visibility: 'hidden' }}>
                    None
                  </td>
                </tr> */}
                    </td>
                  </tr>
                  <tr className="demacation">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="total">Total</td>
                    <td className="total">
                      {singleData.cliams
                        .map((item) => item.qty)
                        .reduce((a, b) => a + b, 0)}
                    </td>
                    <td className="total">
                      {singleData.cliams
                        .map((item) => item.chargesSent)
                        .reduce((a, b) => a + b, 0)}
                    </td>
                    <td className="total">
                      {singleData.cliams
                        .map((item) => item.approvedQty)
                        .reduce((a, b) => a + b, 0)}
                    </td>
                    <td className="total">
                      {singleData.cliams
                        .map((item) => item.chargesApproved)
                        .reduce((a, b) => a + b, 0)}
                    </td>
                  </tr>
                </>
              );
            })}
            <tr className="demacation">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="total">Total</td>
              <td className="total">Charges Sent</td>
              <td className="total">{chargesSent}</td>
              <td className="total">Charges Approved</td>
              <td className="total">{chargesApproved}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Invoice;
