import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import "./viewclaims.css";
import NumberFormat from "react-number-format";
import toast from "react-hot-toast";
import { useHistory } from "react-router";

import { ReloadContext } from "../context/reviewContext/ReviewInitialState";
import ConfirmationDialogRaw from "../components/confirmationDialogue/ConfirmationDialogRaw";
import SimpleBackdrop from "../components/backdrop/Backdrop";
// import jsPDF from 'jspdf'
var jsPDF = require("jspdf");
require("jspdf-autotable");

function ViewClaims(props) {
  let navigate = useHistory();
  const [providerName, setProviderName] = useState();
  const [claims, setClaims] = useState([]);
  const [employeeName, setEmployeeName] = useState();
  const [review, setReview] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [loadingClaimsList, setLoadingClaimsList] = useState(false);
  const [loadingClaimsListErr, setLoadingClaimsListErr] = useState(false);
  const [loadingClaimsListErrMsg, setLoadingClaimsListErrMsg] = useState("");
  const loading = sessionStorage.setItem("loading", false);
  const [reviewInfo, setReviewInfo] = useState(null);
  const Info = sessionStorage.getItem("fullName");
  const [reviewItem, setReviewItem] = useState(null);
  const [reviewedPrice, setReviewedPrice] = useState(0);
  const [reviewedQuantity, setReviewedQuantity] = useState(0);
  const [updatedReviewInfo, setUpdatedReviewInfo] = useState({});
  const role = sessionStorage.getItem("role");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Dione");
  const [clickedItem, setClickedItem] = useState(null);
  const clickedOk = sessionStorage.getItem("clickedOk");

  const updatedBill = sessionStorage.getItem("updatedBill");
  const updatedQuantity = sessionStorage.getItem("updatedQuantity");

  const handleClickListItem = (data) => {
    setClickedItem(data);
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  const id = Number(sessionStorage.getItem("providerId"));
  const providerSelect = props?.providerSelect;
  const setReloadClaimsListForInitiator =
    props?.setReloadClaimsListForInitiator;

  const employeeNo = props.claimsData?.employeeNumber;
  // const employeeNo = localStorage.getItem("employeeNumber");

  const month = localStorage.getItem("datepickerMonth");
  const year = localStorage.getItem("datepickerYear");
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const { reload, newAmount, dispatch } = useContext(ReloadContext);

  const fetchList = useCallback(() => {
    setLoadingClaimsList(true);
    setLoadingClaimsListErr(false);
    const monthNumber = Number(
      props.startDate.toLocaleDateString().split("/")[0]
    );
    const yearNumber = Number(
      props.startDate.toLocaleDateString().split("/")[2]
    );
    const cancelToken = axios.CancelToken.source();
    console.log(props);
    if (
      props.claimTypeText === "Choose Claim Type" ||
      props.claimTypeText === "All Claims"
    ) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${id}&role=${role}&pageNumber=1&pageSize=50`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          setClaims(response.data);
          setLoadingClaimsList(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingClaimsListErr(true);
          setLoadingClaimsListErrMsg(
            error?.message || "Something went wrong, please try again"
          );
        });
      return () => {
        cancelToken.cancel();
      };
    }
    if (
      props.claimTypeText === "NHIS" ||
      props.claimTypeText === "Private" ||
      props.claimTypeText === "plaschema" // The value here, should match the value in the dropdown
    ) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${id}&role=${role}&month=${monthNumber}&year=${yearNumber}&pageSize=200&pageNumber=1&claimType=${props.claimTypeText.toLocaleLowerCase()}`,
          {
            cancelToken: cancelToken.token,
          }
          // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
        )
        .then((response) => {
          setClaims(response.data);
          setLoadingClaimsList(false);

          sessionStorage.setItem("clickedOk", false);
        });

      return () => {
        cancelToken.cancel();
      };
    }
    // if (month && year) {
    //   axios
    //     .get(
    //       `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${id}&role=${role}&month=${month}&year=${year}&pageSize=50&pageNumber=1`,
    //       {
    //         cancelToken: cancelToken.token,
    //       }
    //     )
    //     .then((response) => {
    //       setClaims(response.data)
    //     })
    //     .catch((error) => {
    //       console.error(error)
    //     })
    //   return () => {
    //     cancelToken.cancel()
    //   }
    // } else {
    //   axios
    //     .get(
    //       `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${id}&role=${role}&pageNumber=1&pageSize=50`,
    //       {
    //         cancelToken: cancelToken.token,
    //       }
    //     )
    //     .then((response) => {
    //       setClaims(response.data)
    //     })
    //     .catch((error) => {
    //       console.error(error)
    //     })
    //   return () => {
    //     cancelToken.cancel()
    //   }
    // }
  }, [id, props.startDate, role]);
  // }, [id, month, year, reviewedPrice, props.claimTypeText, props.startDate,role])
  useEffect(() => {
    setProviderName(localStorage.getItem("ProviderName"));
    setEmployeeName(localStorage.getItem("employeeName"));
    // setProviderName(localStorage.getItem("ProviderName"));
    // setEmployeeName(localStorage.getItem("employeeName"));
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    setReviewItem(true);
  }, []);

  const List = claims.filter((data) => data.employeeNumber === employeeNo);
  const claimms = List.map((data) => data.claims);
  const claimsList = claimms.flatMap((data) => data);

  const Diagnosis = claimsList.map((data) => data.diagnosis);

  const EncounterDate = claimsList;
  let ytr = EncounterDate.map((data) => data.authdate);
  let status = EncounterDate.map((data) => data.claimsStatus);
  const CheckStatus =
    status[0] === "SUBMITTED" ? (
      <span className="pending">Pending</span>
    ) : (
      <span className="approve">Approved</span>
    );

  const amount = claimsList ? claimsList.map((data) => data.chargesSent) : "";

  const totalAmount = amount.reduce((a, b) => a + b, 0);

  const amount2 = claimsList
    ? claimsList.map((data) => data.chargesApproved)
    : "";
  // const amount2 = claimsList
  //   ? claimsList.map((data) => data.chargesApproved * data.approvedQty)
  //   : ''
  const PayableAmount = amount2.reduce((a, b) => a + b, 0);

  const reviewData = (data) => {
    setReview(true);
    setReviewInfo(data);
    setUpdatedReviewInfo(data);
    setReviewItem(data);
    let {
      sno,
      authdate,
      description,
      classification,
      diagnosis,
      employeeName,
      employeeNo,
      authorcode,
      consultancyDate,
      qty,
      chargesSent,
      month,
      type,
      protype,
      year,
      day,
    } = data;
    sessionStorage.setItem("ID", sno);
    sessionStorage.setItem("authdate", authdate);
    sessionStorage.setItem("Description", description);
    sessionStorage.setItem("classification", classification);
    sessionStorage.setItem("diagnosis", diagnosis);
    sessionStorage.setItem("employeeName", employeeName);
    sessionStorage.setItem("employeeNo", employeeNo);
    sessionStorage.setItem("authorcode", authorcode);
    sessionStorage.setItem("consultancyDate", consultancyDate);
    sessionStorage.setItem("qty", qty);
    sessionStorage.setItem("chargesSent", chargesSent);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("day", day);
    sessionStorage.setItem("year", year);
    sessionStorage.setItem("protype", protype);
    sessionStorage.setItem("type", type);
  };
  const backClick = () => {
    navigate.push("./claims");
  };

  const setEntity = () => {
    let data = [];
    let Claim = [];
    Claim.detail = claimsList;

    Claim.detail.forEach((element) => {
      let dataInfo = {
        approvedBy: Info,
        qty: element.qty,
        approvedQty: element.approvedQty,
        sno: element.sno,
        chargesApproved: element.chargesApproved,
        comment: element.comment,
      };
      data.push(dataInfo);
    });

    // Claim = data
    // // Claim.approvedBy = Info
    // Claim.qty = data.qty
    // Claim.chargesApproved = data.chargesSent
    // Claim.sno = data.sno
    let answer = window.confirm("You are about to approve This Claim");
    if (data) {
      if (answer && role === "initiator") {
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/initiateclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast.success("Approved Succesfully", {});
          })
          .then(() => {
            // fetchList();
            window.location.reload();
          })
          .catch((error) => console.error(error));
      }
      if (answer && role === "verifier") {
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/verifyclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast.success("Approved Succesfully", {});
          })
          .then(() => {
            // fetchList();
            window.location.reload();
          })
          .catch((error) => console.error(error));
      }
      if (answer && role === "approver") {
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/approveclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast.success("Approved Succesfully", {});
          })
          .then(() => {
            // fetchList();
            window.location.reload();
          })
          .catch((error) => console.error(error));
      }
    }
  };
  useEffect(() => {
    // setData("action", claims[claimsIndex]);
    // fetchList()
  }, [props.claimsData]);

  useEffect(() => {
    fetchList();
  }, [reload, clickedOk, fetchList]);
  // }, [reload, updatedBill, updatedQuantity, clickedOk])
  useEffect(() => {
    // console.log(reviewItem?.sno);
  }, [reviewItem, reviewedPrice]);

  const handleSetCharges = (e, type) => {
    if (type === "quantity") {
      setReviewedQuantity(e.target.value);
      setUpdatedReviewInfo({
        ...updatedReviewInfo,
        approvedQty: Number(e.target.value),
      });
    }
    if (type === "charges") {
      setReviewedPrice(e.target.value);
      setUpdatedReviewInfo({
        ...updatedReviewInfo,
        chargesApproved: Number(e.target.value),
      });
    }
  };

  const handleSubmitReview = () => {
    let answer = window.confirm("You have are about to review");

    if (updatedReviewInfo) {
      if (answer) {
        axios
          .put(
            `https://portalapi.lifeworthhmo.com/api/Claims/${reviewInfo?.sno}`,
            updatedReviewInfo
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast.success("Reviewed Succesfully", {});
          })
          .then(() => {
            setReview(false);
            fetchList();
          })
          .then(() => {
            dispatch({
              type: "RELOAD",
              payload: reloadPage,
            });
          })
          .catch((error) => console.error(error));
      }
    }
  };

  const handleSetReview = () => {
    setReview(false);
    // setReviewedPrice(0);
    // setReviewedPrice(0)
  };

  // FUNCTION TO GENERATE AND DOWNLOAD PAGE AS PDF
  function generate() {
    var doc = new jsPDF.jsPDF();

    // Simple html example
    doc.autoTable({ html: "#table" });

    doc.save("table.pdf");
  }

  // USE EFFECTS
  useEffect(() => {
    fetchList();
  }, [props.clickedSearch, fetchList]);

  // USE EFFECT FOR LOADING FUNCTIONALITY

  useEffect(() => {}, [loading]);

  // USE EFFECT TO UPDATE THE CURRENTLY CLICKED ITEM
  useEffect(() => {}, [clickedItem]);

  return (
    <>
      {sessionStorage.getItem("loading") === "true" && <SimpleBackdrop />}
      {/* <Review setReview={setReview} review={review} reviewInfo={reviewInfo} /> */}

      <div className="viewClaimsWrapper">
        {/* <Navbar /> */}
        <div className="viewClaimsRight">
          {/* <div className='claimsDetailsWrapper'>
            <div className='claimsDetailsTop'>
              <div onClick={backClick} className='viewClaimsback'>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              <div className='Title-Approve'>
                <button className='approve-btn' onClick={setEntity}>
                  Approve
                </button>
              </div>
            </div>
            <div className='claimsDetailsBottom'>
              <Toaster
              position="top-center"
              reverseOrder={false}
          />
              <div className='details-wrapper'>
                <div>
                  <h2 className='detail-txt'>{employeeName}</h2>
                  <h2 className='detail-txt'>{employeeNo}</h2>
                  <h2>
                    Encounter Date:<span className='detail-txt'>{ytr[0]}</span>
                  </h2>
                </div>
                <div>
                  <h2>Pa Code:</h2>
                  <h2>
                    Diagnosis:
                    {Diagnosis.map((data) => (
                      <span className='detail-txt'>
                        {data}
                        <br />
                      </span>
                    ))}
                  </h2>
                  <h2>Status:{CheckStatus}</h2>
                </div>
                <div>
                  <h2>
                    Billed:{' '}
                    <NumberFormat
                      value={totalAmount}
                      displayType={'text'}
                      className='amt'
                      thousandSeparator={true}
                      prefix={'NGN'}
                    />
                  </h2>
                  <h2>
                    Payable:{' '}
                    <NumberFormat
                      value={PayableAmount}
                      displayType={'text'}
                      className='amt'
                      thousandSeparator={true}
                      prefix={'NGN'}
                    />{' '}
                  </h2>
                  <h2>
                    Created By:
                    <span className='detail-txt'>{providerName}</span>
                  </h2>
                  <div className='Title-Approve'>
                    <button className='approve-btn' onClick={setEntity}>
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="styledTableWrapper reviewTable">
            <table className="styled-table" id="table">
              <thead>
                <tr>
                  <th>Treatment Items</th>
                  <th>Submitted Qty</th>
                  <th>Submitted Bill</th>
                  <th>Approved Qty</th>
                  <th>Payable Bill</th>
                  <th>Comment</th>
                  <th>
                    {(role === "verifier" || role === "initiator") && "Review"}
                  </th>
                </tr>
              </thead>
              {claims.length === 0 && !loadingClaimsList && (
                <div className="noClaims">No claims available</div>
              )}

              {loadingClaimsList && (
                <div className="noClaims">Loading claims...</div>
              )}

              {console.log(loadingClaimsList)}

              <tbody className="">
                {claimms
                  ? claimsList.map((data, index) => (
                      <tr key={index}>
                        <td>{data.description}</td>
                        <td>{data.qty}</td>
                        <td>{data.chargesSent}</td>
                        <td>{data.approvedQty}</td>
                        <td>{data.chargesApproved}</td>
                        <td>{data.comment}</td>
                        {/* <td> */}
                        {/* {data.approvedQty || data.approvedQty === 0
                            ? data.approvedQty
                            : data.qty} */}
                        {/* 

                            UNCOMMENT THIS BLOCK TO GO BACK TO PREVIOUS REVIEW FORM
                            
                            
                          {review ? (
                            reviewItem?.sno === data.sno ? (
                              <input
                                type='number'
                                onChange={(e) =>
                                  handleSetCharges(e, 'quantity')
                                }
                                className='reviewInput'
                                min={0}
                              />
                            ) : (
                              data.approvedQty
                            )
                          ) : (
                            data.approvedQty
                          )}
                        </td>
                        <td>
                          {review ? (
                            reviewItem?.sno === data.sno ? (
                              <input
                                type='number'
                                onChange={(e) => handleSetCharges(e, 'charges')}
                                className='reviewInput'
                                min={0}
                              />
                            ) : (
                              data.chargesApproved
                            )
                          ) : (
                            data.chargesApproved
                          )}
                          */}

                        {/* </td> */}
                        <td>
                          {/* <Link to='/adjustclaims'> */}
                          {(role === "verifier" || role === "initiator") && (
                            <button
                              className="claims-btn-review"
                              onClick={() => handleClickListItem(data)}
                            >
                              Review
                            </button>
                          )}
                          <ConfirmationDialogRaw
                            id="ringtone-menu"
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            value={value}
                            data={clickedItem}
                            providerSelect={providerSelect}
                            setReloadClaimsListForInitiator={
                              setReloadClaimsListForInitiator
                            }
                          />
                          {/* 
                          UNCOMMENT THIS BLOCK TO GO BACK TO PREVIOUS REVIEW TYPE
                          
                          {review ? (
                            reviewItem?.sno === data.sno ? (
                              <div className='reviewBtns'>
                                <div
                                  className='cancelReview'
                                  onClick={handleSetReview}
                                >
                                  Cancel
                                </div>
                                <div
                                  className='approveReview'
                                  onClick={handleSubmitReview}
                                >
                                  Done
                                </div>
                              </div>
                            ) : (
                              <button
                                className='claims-btn-review'
                                onClick={() => reviewData(data)}
                              >
                                Review
                              </button>
                            )
                          ) : (
                            <button
                              className='claims-btn-review'
                              onClick={() => reviewData(data)}
                            >
                              Review
                            </button>
                          )}

                            */}
                          {/* </Link> */}
                        </td>
                      </tr>
                    ))
                  : "dgfsdg"}
              </tbody>
            </table>
          </div>
          <div className="total-due">
            <div className="totalWrapper">
              <h2 className="total-amt-list">
                Total Provider Bill:{" "}
                <NumberFormat
                  value={totalAmount}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
              <h2 className="total-amt-list">
                Total Approved Amount:{" "}
                <NumberFormat
                  value={PayableAmount}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
            </div>
            <button
              className="approve-btn-list"
              onClick={setEntity}
              disabled={!claims.length > 0}
            >
              Approve
            </button>
          </div>
          {/* <div className='approveClaimBtn' onClick={setEntity}>
            Approve
          </div> */}
        </div>

        {/* <h2 className='total-amt'>
          Total Due:{' '}
          <NumberFormat
            value={PayableAmount}
            displayType={'text'}
            className='amt'
            thousandSeparator={true}
            prefix={'NGN'}
          />
        </h2> */}
      </div>
    </>
  );
}

export default ViewClaims;

