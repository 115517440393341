import React, { useState, useEffect } from "react";
import axios from "axios";
import "../claims.css";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import * as AiIcons from "react-icons/ai";
import Dashboard from "../../Dashboard/Dashboard";
import Navbar from "../../sidebar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  autoComplete,
  handleChange,
  handleLoading,
  handleSelectedProvider,
} from "../../functions/AutoComplete";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import { toast } from "react-toastify";

export default function Pendingclaims() {
  const [list, setList] = useState([]);
  const [providerList, setProviderList] = useState(false);
  const [filterList, setFilterList] = useState({
    providerList: false,
    monthList: false,
  });
  const [allList, setAllList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedFilterParams, setSelectedFilterParams] = useState({
    selectedProvider: "",
    selectedMonth: "",
  });
  const [a, setA] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProvidersListPC, setLoadingProvidersListPC] = useState(false);
  const [loadingProvidersListPCErr, setLoadingProvidersListPCErr] =
    useState(false);
  const [loadingProvidersListPCErrMsg, setLoadingProvidersListPCErrMsg] =
    useState("");
  const [errorPage, setErrorPage] = useState(false);
  const [loadingClaimsList, setLoadingClaimsList] = useState(false);
  const [loadingClaimsListErr, setLoadingClaimsListErr] = useState(false);
  const [loadingClaimsListErrMsg, setLoadingClaimsListErrMsg] = useState("");

  // console.log(filteredList)

  let filtered;

  let navigate = useHistory();
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    setLoading(true);
    setLoadingClaimsList(true);
    setLoadingClaimsListErr(false);
    setLoadingClaimsListErrMsg("");
    axios
      .get(
        `https://portalapi.lifeworthhmo.com/api/Claims/pending-hospital?role=${role}`,
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((response) => {
        setList(response.data);
        setLoading(false);
        setLoadingClaimsList(false);
      })
      .catch((err) => {
        setLoading(false);
        setLoadingClaimsList(false);
        setLoadingClaimsListErr(true);
        setLoadingClaimsListErrMsg(
          err?.message || "An error occured, please try again."
        );
        console.log(err);
      });
    return () => {
      cancelToken.cancel();
    };
  }, [role]);

  useEffect(() => {
    // window.addEventListener('click', (e) => {
    // if (e.target.className === 'pendingStatusFilter') {
    // console.log('het')
    // }
    // providerList === true && setProviderList(false)
    // console.log(providerList)
    // })
  }, [providerList]);

  useEffect(() => {
    const cachedData = sessionStorage.getItem("providers");
    if (cachedData) {
      setAllList(JSON.parse(cachedData));
      setFilteredList(JSON.parse(cachedData));
    } else {
      const cancelToken = axios.CancelToken.source();

      setLoadingProvidersListPC(true);
      setLoadingClaimsListErr(false);

      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Provider?pageSize=1000000&pageNumber=1`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          sessionStorage.setItem(
            "providers",
            JSON.stringify(response?.data?.result)
          );
          setAllList(response?.data?.result);
          setFilteredList(response?.data?.result);
          setLoadingProvidersListPC(false);
        })
        .catch((err) => {
          setLoadingProvidersListPC(false);
          setLoadingProvidersListPCErr(true);
          setLoadingProvidersListPCErrMsg(
            err?.message || "An error occured, please try again."
          );
          console.log(err);
        });

      // .catch((error) => console.error(error))
      return () => {
        cancelToken.cancel();
      };
    }
  }, []);

  useEffect(() => {}, [selectedProvider]);
  useEffect(() => {}, [loading, loadingProvidersListPC]);

  const setData = (data) => {
    sessionStorage.removeItem("providerId");
    let { providerName, idProvider } = data;
    sessionStorage.setItem("ProviderName", providerName);
    sessionStorage.setItem("providerId", idProvider);
  };

  const backClick = () => {
    navigate.push("./dash");
  };

  return (
    <div className="pendingStatusWrapper">
      {/* {loading && handleLoading(setLoading)}
      {loading && <Loading />} */}
      <Navbar />
      <div className="pendingStatusRight">
        <div className="pendingStatusFilterWrapper">
          <div onClick={backClick} className="backToPending">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>

          <div className="pendingStatusFilter">
            <input
              type="text"
              className="pendingStatusFilterInput"
              placeholder="Search provider"
              onClick={() =>
                autoComplete("provider", setFilterList, filterList)
              }
              onChange={(e) =>
                handleChange(
                  e,
                  filtered,
                  allList,
                  setFilteredList,
                  setSelectedProvider,
                  filterList,
                  setFilterList
                )
              }
              value={selectedProvider}
            />
            <span>
              <FontAwesomeIcon icon={faFilter} />
            </span>
          </div>
          <div
            className="showAll"
            onClick={() => {
              window.location.reload();
            }}
          >
            Show all claims
          </div>
          {filterList.providerList && (
            <div className="FilteredProviderList">
              {
                loadingProvidersListPC
                  ? "Loading..."
                  : loadingProvidersListPCErr
                  ? loadingProvidersListPCErrMsg
                  : filteredList.length > 0
                  ? filteredList.map((singleItem, index) => {
                      return (
                        <div
                          className="FilteredProviderListItem"
                          key={index}
                          onClick={(e) =>
                            handleSelectedProvider(
                              "provider",
                              e,
                              setSelectedProvider,
                              setA,
                              filteredList,
                              setSelectedFilterParams,
                              selectedFilterParams,
                              setFilterList
                            )
                          }
                        >
                          {singleItem.providerName}
                        </div>
                      );
                    })
                  : "Please input a valid provider name"

                // !loadingProvidersListPC
                // ? filteredList.length > 0
                //   ? filteredList.map((singleItem, index) => {
                //       return (
                //         <div
                //           className='FilteredProviderListItem'
                //           key={index}
                //           onClick={(e) =>
                //             handleSelectedProvider(
                //               'provider',
                //               e,
                //               setSelectedProvider,
                //               setA,
                //               filteredList,
                //               setSelectedFilterParams,
                //               selectedFilterParams,
                //               setFilterList
                //             )
                //           }
                //         >
                //           {singleItem.providerName}
                //         </div>
                //       )
                //     })
                //   : 'Please input provider name'
                //   : 'Loading providers list...'
              }
            </div>
          )}
          {/* {filterList.providerList && (
            <div className='FilteredProviderList'>
              {!loadingProvidersListPC
                ? filteredList.length > 0
                  ? filteredList.map((singleItem, index) => {
                      return (
                        <div
                          className='FilteredProviderListItem'
                          key={index}
                          onClick={(e) =>
                            handleSelectedProvider(
                              'provider',
                              e,
                              setSelectedProvider,
                              setA,
                              filteredList,
                              setSelectedFilterParams,
                              selectedFilterParams,
                              setFilterList
                            )
                          }
                        >
                          {singleItem.providerName}
                        </div>
                      )
                    })
                  : 'Please input provider name'
                : 'Loading providers list...'}
            </div>
          )} */}
        </div>
        {/* <button onClick={backClick} className='bck'>
          Back to Dashboard
        </button> */}
        <div className="styledTableWrapper">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Claims List</th>
                <th>Select Provider</th>
              </tr>
            </thead>

            {loadingClaimsList && (
              <div className="noClaims">Loading claims...</div>
            )}
            {list.length === 0 &&
              !loadingClaimsList &&
              !loadingClaimsListErr && (
                <div className="noClaims">No Pending claims</div>
              )}
            {loadingClaimsListErr && (
              <div className="noClaims">{loadingClaimsListErrMsg}</div>
            )}
            {
              list.length > 0 &&
                !loadingClaimsList &&
                list.map((data, index) => (
                  <tbody className="" key={index}>
                    <tr>
                      <td>{a ? a.providerName : data.providerName}</td>

                      {(() => {
                        if (role === "initiator") {
                          return (
                            <td>
                              <Link to="/claims">
                                <button
                                  className="btn-pendingreview"
                                  onClick={() => {
                                    // console.log(data)
                                    // console.log(a)
                                    setData(a ? a : data);
                                  }}
                                >
                                  <AiIcons.AiFillEye />
                                </button>
                              </Link>
                            </td>
                          );
                        } else if (role === "verifier") {
                          return (
                            <td>
                              <Link to="/claims">
                                {/* <Link to='/list'> */}
                                <button
                                  className="btn-pendingreview"
                                  onClick={() => setData(a ? a : data)}
                                >
                                  <AiIcons.AiFillEye />
                                </button>
                              </Link>
                            </td>
                          );
                        } else {
                          return (
                            <td>
                              <Link to="/claims">
                                {/* <Link to='viewlist'> */}
                                <button
                                  className="btn-pendingreview"
                                  onClick={() => setData(a ? a : data)}
                                >
                                  <AiIcons.AiFillEye />
                                </button>
                              </Link>
                            </td>
                          );
                        }
                      })()}
                    </tr>
                  </tbody>
                ))
              // ) : (
              //   <div className='noClaims'>No Claims Available</div>
              // )
            }
          </table>
        </div>
      </div>
    </div>
  );
}
