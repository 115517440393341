import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function NonAsyncSelect(props) {
  const { list, setSelectedPaymentStatus, title, setSelectedClaimType } = props;

  const handleSetFilterData = (option) => {
    if (title === "Filter by status") {
      setSelectedPaymentStatus(option.target.textContent);
    }

    if (title === "Filter by claim type") {
      setSelectedClaimType(option.target.textContent);
    }
  };

  return (
    <Autocomplete
      id="country-select-demo"
      size="small"
      sx={{ width: 200, mr: 5 }}
      options={list && list}
      autoHighlight
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      onChange={(e) => handleSetFilterData(e)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {/* <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=''
          /> */}
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  {
    label: "All",
  },
  { label: "Vetting Pending" },
  {
    label: "Vetting Ongoing",
  },
  {
    label: "Vetting Completed",
  },

  {
    label: "Vetting Completed",
  },
];
