import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
export default function AlertDialog(props) {
  const {
    open,
    handleClose,
    data,
    setUpdatedPaymentStatus,
    updatedPaymentStatus,
    currentClaim,
  } = props;
  const idProvider = data?.[0].cliams?.[0]?.idProvider;

  // const startDate = new Date()
  const monthNumber = Number(data?.[0].cliams?.[0]?.month);
  // const monthNumber = Number(startDate.toLocaleDateString().split('/')[0])
  const yearNumber = Number(data?.[0].cliams?.[0]?.year);
  // const yearNumber = Number(startDate.toLocaleDateString().split('/')[2])

  // FUNCTION TO UPDATE PAYMENT STATUS
  const updatePaymentStatus = () => {
    // const cancelToken = axios.CancelToken.source()

    const paymentStatusData = {
      providerId: idProvider,
      month: monthNumber,
      year: yearNumber,
      claimType: currentClaim.claimType,
      paidBy: sessionStorage.fullName,
      status: "paid",
    };
    const id = toast.loading("Updating Claim...");

    axios
      .post(
        `https://portalapi.lifeworthhmo.com/api/Claims/make-as-paid`,
        paymentStatusData,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setUpdatedPaymentStatus(!updatedPaymentStatus);
        toast.update(id, {
          render: "Payment Status Successfully Updated",
          type: "success",
          autoClose: 2000,
          closeButton: true,
          isLoading: false,
        });
        handleClose();
      })
      .catch((error) => {
        toast.update(id, {
          render: "Could not update status, Please try again",
          type: "error",
          autoClose: 2000,
          closeButton: true,
          isLoading: false,
        });
        console.log(error);
        handleClose();
      });

    // return () => {
    //   cancelToken.cancel()
    // }
  };

  return (
    <div>
      {/* <Button variant='outlined' onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Mark Claim as Paid?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please only click on confirm if the payment has been made.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updatePaymentStatus} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
