import * as FaIcons from 'react-icons/fa'

export const AccountantSideBarData = [
  {
    title: 'Dashboard',
    path: '/dash',
    icon: <FaIcons.FaTachometerAlt />,
    cName: 'nav-text',
  },

  {
    title: 'Total Bill Amount',
    path: '/financelist',
    icon: <FaIcons.FaSearch />,
    cName: 'nav-text',
  },
]
