import React from 'react'
import './financeDownloadPage.css'
// var jsPDF = require('jspdf')
// require('jspdf-autotable')
import JsPDF from 'jspdf'

const FinanceDownloadPage = (props) => {
  const { data, generatePDF } = props
  console.log(props)
  // FUNCTION TO GENERATE AND DOWNLOAD PAGE AS PDF
  // function generate() {
  //   var doc = new jsPDF.jsPDF('landscape')
  //   // Simple html example
  //   doc.autoTable({ html: '#table', useCss: true })
  //   doc.save('table.pdf')
  // }

  // const generatePDF = () => {
  //   const report = new JsPDF('p', 'px', [1600, 1250])
  //   report.html(document.querySelector('#report')).then(() => {
  //     report.save('report.pdf')
  //   })
  // }
  return (
    <div className='financeDownloadPageWrapper'>
      <table className='financeDownloadPageTable' id='report'>
        <thead className='financeDownloadPageTableHead'>
          <tr>
            <th colspan='11' className='lifeworthTitle'>
              LIFEWORTH HMO
            </th>
          </tr>
          <tr>
            <th colspan='11' className='lifeworthAddress'>
              175 Ikorodu Road, Palmgrove Lagos.
            </th>
          </tr>
          <tr>
            <th colspan='11' className='voucherTitle'>
              Payment Voucher
            </th>
          </tr>

          <tr>
            <th onClick={generatePDF}>S/N</th>
            <th>Date</th>
            <th>Patient Id</th>
            <th>Name</th>
            <th>Diagnosis</th>
            <th>Treatment Items</th>
            <th>Submitted Qty</th>
            <th>Submitted Bill</th>
            <th>Approved Qty</th>
            <th>Approved Bill</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody className='accountantTableBody'>
          {data?.map((singleData, index) => {
            const { employeeName, diagnosis, treatmentDate } =
              singleData?.cliams[0]

            return (
              <>
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {treatmentDate.slice(0, 10).split('-')[2] +
                      '-' +
                      new Date(treatmentDate)
                        .toLocaleString('default', {
                          month: 'long',
                        })
                        .slice(0, 3) +
                      '-' +
                      treatmentDate.slice(0, 10).split('-')[0]}
                  </td>
                  <td>{singleData.employeeNo}</td>
                  <td>{employeeName}</td>
                  <td>{diagnosis}</td>
                  <td>
                    {singleData.cliams.map((treatmentItem) => {
                      return (
                        <tr>
                          <td>{treatmentItem.description}</td>
                        </tr>
                      )
                    })}
                  </td>
                  <td>
                    {singleData.cliams.map((treatmentItem) => {
                      return (
                        <tr>
                          <td>{treatmentItem.qty}</td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                      <td className='none' style={{ visibility: 'hidden' }}>
                        None
                      </td>
                    </tr> */}
                  </td>
                  <td>
                    {singleData.cliams.map((treatmentItem) => {
                      return (
                        <tr>
                          <td>{treatmentItem.chargesSent}</td>
                          <br />
                        </tr>
                      )
                    })}
                    {/* <tr>
                      <td className='total'>
                        {singleData.cliams
                          .map((item) => item.chargesSent)
                          .reduce((a, b) => a + b, 0)}
                      </td>
                    </tr> */}
                  </td>
                  <td>
                    {singleData.cliams.map((treatmentItem) => {
                      return (
                        <tr>
                          <td>{treatmentItem.approvedQty}</td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                      <td className='none' style={{ visibility: 'hidden' }}>
                        None
                      </td>
                    </tr> */}
                  </td>
                  <td>
                    {singleData.cliams.map((treatmentItem) => {
                      return (
                        <tr>
                          <td>{treatmentItem.chargesApproved}</td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                      <td className='none' style={{ visibility: 'hidden' }}>
                        None
                      </td>
                    </tr> */}
                  </td>
                  <td>
                    {singleData.cliams.map((treatmentItem) => {
                      return (
                        <tr>
                          <td className='comment'>
                            {treatmentItem.comment ? (
                              treatmentItem.comment
                            ) : (
                              <div style={{ visibility: 'hidden' }}>
                                'No comment'
                              </div>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                      <td className='none' style={{ visibility: 'hidden' }}>
                        None
                      </td>
                    </tr> */}
                  </td>
                </tr>
                <tr className='demacation'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='total'>Total</td>
                  <td className='total'>
                    {singleData.cliams
                      .map((item) => item.qty)
                      .reduce((a, b) => a + b, 0)}
                  </td>
                  <td className='total'>
                    {singleData.cliams
                      .map((item) => item.chargesSent)
                      .reduce((a, b) => a + b, 0)}
                  </td>
                  <td className='total'>
                    {singleData.cliams
                      .map((item) => item.approvedQty)
                      .reduce((a, b) => a + b, 0)}
                  </td>
                  <td className='total'>
                    {singleData.cliams
                      .map((item) => item.chargesApproved)
                      .reduce((a, b) => a + b, 0)}
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
    </div>
    // <table className='financeDownloadPageWrapper' id='table'>
    //   <thead>
    //     {/* <div className='financeDownloadPageHeader'> */}
    //     <tr className='financeDownloadPageHeaderTop'>
    //       <th className='lifeworthTitle' onClick={generate}>
    //         LIFEWORTH HMO
    //       </th>
    //       <p className='lifeworthAddress'>175 Ikorodu Road, Palmgrove Lagos.</p>
    //     </tr>
    //     <div className='financeDownloadPageHeaderBottom'>
    //       <div className='voucherTitle'></div>
    //     </div>
    //     {/* </div> */}
    //   </thead>

    // </table>
  )
}

export default FinanceDownloadPage
