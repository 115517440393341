import axios from "axios";
import e from "cors";
import Error from "../components/error/Error";

export const autoComplete = (type, setList, list) => {
  if (type === "provider") {
    setList({ ...list, providerList: !list.providerList });
  }
  if (type === "month") {
    setList({ ...list, monthList: !list.monthList });
  }
};

export const handleChange = (
  e,
  filtered,
  allList,
  setFilteredList,
  setSelectedProvider,
  filterList,
  setFilterList
) => {
  if (!filterList.providerList) {
    setFilterList({ ...filterList, providerList: true });
  }
  filtered = allList.filter((listItem) =>
    listItem.providerName.includes(
      `${e.currentTarget.value.toLocaleUpperCase()}`
    )
  );
  setSelectedProvider(e.currentTarget.value.toLocaleUpperCase());

  setFilteredList(filtered);
};
export const handleMonthChange = (
  e,
  filtered,
  allList,
  setFilteredMonths,
  setSelectedProvider,
  setFilterList,
  filterList
) => {
  if (!filterList.monthList) {
    setFilterList({ ...filterList, monthList: true });
  }
  filtered = allList.filter((listItem) =>
    listItem.name.includes(`${e.currentTarget.value.toLocaleUpperCase()}`)
  );
  setSelectedProvider(e.currentTarget.value.toLocaleUpperCase());

  setFilteredMonths(filtered);
  console.log(filtered);
};

export const handleSelectedProvider = (
  type,
  e,
  setSelectedProvider,
  setA,
  filteredList,
  setSelectedFilterParams,
  SelectedFilterParams,
  setFilterList
) => {
  if (type === "provider") {
    console.log(filteredList);
    setSelectedFilterParams({
      ...SelectedFilterParams,
      selectedProvider: e.target.textContent,
    });
    // setSelectedProvider(e.target.textContent)
    setSelectedProvider(e.target.textContent);
    // console.log(e.target.textContent)
    filteredList.map((singleItem) => {
      if (singleItem.providerName === e.target.textContent) {
        setA(singleItem);
      }
      return;
    });
    // setFilterList({ filterList: false, monthList: false })
    // setFilterList({ filterList: false, monthList: false })
  }
  if (type === "month") {
    // setSelectedProvider(e.target.textContent)
    console.log(filteredList);
    setSelectedFilterParams({
      ...SelectedFilterParams,
      selectedMonth: e.target.textContent,
    });
    setSelectedProvider(e.target.textContent);
    filteredList.map((singleItem) => {
      if (singleItem.name === e.target.textContent) {
        setA(singleItem);
      }
      return;
    });
  }
  // setFilterList({ filterList: false, monthList: false })
};

export const getAllProviders = (setAllList, setFilteredList) => {
  console.log("function call");

  const cancelToken = axios.CancelToken.source();
  axios
    .get(
      `https://portalapi.lifeworthhmo.com/api/Provider?pageSize=10000&pageNumber=1`,
      { cancelToken: cancelToken.token }
    )
    .then((response) => {
      setAllList(response?.data?.result);
      setFilteredList(response?.data?.result);
    })

    .catch((error) => console.error(error));

  return () => {
    cancelToken.cancel();
  };
};

export const handleYearChange = (e, setYear) => {
  setYear(e.target.value);
};

export const handleLoading = (setLoading) => {
  setTimeout(() => {
    setLoading(false);
  }, 90000);
};

/*
      const monthNumber = Number(startDate.toDateString().split('/')[0])
      const yearNumber = Number(startDate.toDateString().split('/')[0])
*/

