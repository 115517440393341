import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './AdjustClaims.css'
import { useForm } from 'react-hook-form'
import '../Claims/authorization/authorization.css'
import '../Claims/Medicals/medical.css'
import '../Claims/details/details.css'
import toast, { Toaster } from 'react-hot-toast'
import { useHistory } from 'react-router'
import Navbar from '../sidebar/Navbar'

function AdjustClaims() {
  const [option] = useState({ obj: null })
  const [options, setOptions] = useState()
  const [apiDataMedical, setApiDataMedical] = useState([])
  const [type, setType] = useState('')
  const [proType, setProType] = useState('')
  const [day, setDay] = useState(0)
  const [month, setMonth] = useState(0)
  const [year, setYear] = useState(0)
  const [classification, setClassification] = useState(false)
  const [description, setDescription] = useState('')
  const [diagnosis, setDiagnosis] = useState('')
  const [authDate, setAuthDate] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [employeeNo, setEmployeeNo] = useState('')
  const [authorCode, setAuthorCode] = useState('')
  const [consultancyDate, setConsultancyDate] = useState('')
  const [charges, setCharges] = useState('')
  const [qty, setQty] = useState('')
  const [id, setID] = useState(0)
  const [provideId, setProvideId] = useState(0)

  useEffect(() => {
    setID(sessionStorage.getItem('ID'))
    setClassification(sessionStorage.getItem('classification'))
    setDescription(sessionStorage.getItem('Description'))
    setAuthDate(sessionStorage.getItem('authdate'))
    setDiagnosis(sessionStorage.getItem('diagnosis'))
    setEmployeeName(sessionStorage.getItem('employeeName'))
    setEmployeeNo(sessionStorage.getItem('employeeNo'))
    setAuthorCode(sessionStorage.getItem('authorcode'))
    setConsultancyDate(sessionStorage.getItem('consultancyDate'))
    setQty(sessionStorage.getItem('qty'))
    setCharges(sessionStorage.getItem('chargesSent'))
    setProvideId(Number(sessionStorage.getItem('ProviderId')))
    setMonth(Number(sessionStorage.getItem('month')))
    setDay(Number(sessionStorage.getItem('day')))
    setYear(Number(sessionStorage.getItem('year')))
    setProType(sessionStorage.getItem('protype'))
    setType(sessionStorage.getItem('type'))
  }, [])
  let navigate = useHistory()

  // const toggleModal = (e) => {
  //   setModal(!modal);
  //   e.preventDefault()

  // };

  const backClick = () => {
    navigate.push('./viewclaims')
  }

  const [amountCalc, setAmountCalc] = useState(0)

  const Description = (event) => {
    axios
      .get(`https://portalapi.lifeworthhmo.com/api/Classification`)
      .then((response) => {
        setApiDataMedical(response.data)

        const price = apiDataMedical
          .filter((data) => data.description === event.target.value)
          .map((data) => data.price)

        setOptions(...price)
        if (options) {
          toast('Unit Price Fetched Succesfully', {
            style: {
              borderRadius: '10px',
              background: '#F8A370',
              color: '#fff',
            },
          })
        }
      })
  }
  const chargesApproved = () => {
    const value = Number(document.getElementById('charges').value)
    const calcAmount = value * options
    setAmountCalc(calcAmount)
  }

  const Sno = Number(id)

  const { register, handleSubmit } = useForm({})

  const addUp = (data) => {
    data.EmployeeName = employeeName
    data.chargesApproved = amountCalc
    data.chargesSent = Number(charges)
    data.employeeNo = employeeNo
    data.treatmentDate = consultancyDate
    data.consultancyDate = consultancyDate
    data.idProvider = provideId
    data.consultancyFee = null
    data.diagnosis = diagnosis
    data.classification = classification
    data.description = description
    data.authorcode = authorCode
    data.authdate = authDate
    data.procedure = null
    data.qty = Number(qty)
    data.approvedQty = data.qty2
    data.idCompany = provideId
    data.userId = null
    data.idProduct = null
    data.month = month
    data.day = day
    data.year = year
    data.protype = proType
    data.type = type
    data.sno = Number(id)
    // data.approvedBy = "Tolu"

    let answer = window.confirm('You have are about to review')

    if (data) {
      if (answer) {
        axios
          .put(`https://portalapi.lifeworthhmo.com/api/Claims/${Sno}`, data)
          .then((response) => console.debug(response))
          .then(() => {
            toast('Reviewed Succesfully', {
              style: {
                borderRadius: '10px',
                background: '#F8A370',
                color: '#fff',
              },
            })
          })
          .then(() => {
            backClick()
          })
          .catch((error) => console.error(error))
      }
    }
    // data.type = type
    // data.chargesApproved = amountCalc
    // data.idProvider = providerId
    // data.idCompany = providerId
    // data.employeeNo = option.employeeNo
    // data.employeeName = option.fullName || option.name
    // data.employeeSurname = option.surname
    // data.consultancyDate = dates
    // data.TreatmentDate = dates
    // data.Day = day
    // data.Month = month
    // data.year = year
    // data.unitPrice = options
    //  console.log(data);
    //  claims.push(data)
    //  console.log(claims);
    //  console.log(claims.length);
    // //  if (claims.length >= 0){
    // //    onSubmit()
    // //  }
    //   setApiDataMedical(null)
    //   setAmountCalc('')
    //   setOptions("")
    //   reset()
  }
  // const onSubmit = () => {
  //  console.log(claims);

  // alert(`You are about to submit treatment for ${option.surname} ${option.fullName || option.name}`)
  // setApiDataMedical(null)
  // setAmountCalc('')
  // setOptions("")

  // axios
  //     .post(
  //         'http://15.237.160.238:50/api/Claims',
  //         claims,
  //         { headers: { 'Content-Type': 'application/json' }}
  //      )
  //     .then(response => {console.log(response)})
  //     .then(()=>{
  //      toast("Claims Submitted Succesfully", {
  //       duration: 4000,
  //       style: {
  //       borderRadius: '10px',
  //       background: '#F8A370',
  //       color: '#fff',
  //    },
  //     },
  //     )
  //     })
  //     .catch(error => {console.log(error)});

  // };

  return (
    <>
      <div className='adjustClaimsWrapper'>
        <Navbar />
        <section className='claims-wrapper'>
          <div className='heading'>
            <h1>Review Claims</h1>
            <div>
              <Toaster position='top-center' reverseOrder={false} />
            </div>
            <button onClick={backClick} className='addClaimsReloadBack'>
              Back
            </button>
          </div>
          <form onSubmit={handleSubmit(addUp)}>
            <div className='details'>
              <div className='status'>
                <label htmlFor='status'>Fullname:</label>
                <input
                  type='text'
                  className='stat'
                  value={employeeName}
                  disabled
                />
              </div>
              <div className='code-name'>
                <label for='Enn code'>Enn Code:</label>
                <input
                  type='text'
                  className='stat'
                  value={employeeNo}
                  disabled
                />
              </div>
              <div className='authorization'>
                <div className='Company'>
                  <label htmlFor='company'>Authorization Code/Date</label>
                  <input
                    type='text'
                    className='author'
                    value={authorCode}
                    required
                  />
                  <input type='text' className='author' value={authDate} />
                </div>
                <div className='headin'>
                  <div className='diagnosis'>
                    <label htmlFor='diagnosis'>Diagnosis</label>
                    <input
                      type='text'
                      value={diagnosis}
                      className='diagno'
                      disabled
                    ></input>
                  </div>

                  <div className='Consultation-date'>
                    <label htmlFor='diagnosis'>Consultation Date</label>
                    <input
                      type='text'
                      value={consultancyDate.substring(0, 10)}
                      disabled
                    ></input>
                  </div>
                </div>
              </div>
              <div className='Medicals'>
                <div className='medicals'>
                  <div className='section-1'>
                    <div className='classification-desc'>
                      {/* <label htmlFor=''>Classification</label>
              <input type="text" className='charges-approved' value={classification} disabled></input> */}
                      <label htmlFor=''>Description</label>
                      <input
                        type='text'
                        className='Name-input'
                        value={description}
                        disabled
                      ></input>
                      <select
                        name=''
                        id=''
                        className='charges-approved'
                        onClick={Description}
                        required
                      >
                        {!apiDataMedical ? (
                          <option value=''>--select--</option>
                        ) : (
                          apiDataMedical.map((data, index) => {
                            return (
                              <>
                                <option
                                  key={index}
                                  value={data.description}
                                >{`${data.description}`}</option>
                                <input
                                  type='text'
                                  className='charges-approved'
                                />
                              </>
                            )
                          })
                        )}
                      </select>
                    </div>
                    <div className='approved'>
                      <label htmlFor=''>No of days/Qty:</label>
                      <input
                        type='text'
                        className='Name-input'
                        value={qty}
                        disabled
                      ></input>
                      <input
                        type='number'
                        className='charges-approved'
                        id='charges'
                        {...register('qty2', { valueAsNumber: true })}
                        onChange={chargesApproved}
                        min={0}
                      />
                      <label htmlFor=''>Unit price:</label>
                      <input
                        type='text'
                        className='charges-approved'
                        value={options}
                        disabled
                      />
                    </div>
                    <div className='amount'>
                      <label htmlFor=''>Charges Approved:</label>
                      <input
                        type='text'
                        className='Name-input'
                        value={charges}
                        disabled
                      ></input>
                      <input
                        type='number'
                        className='charges'
                        id='approved-charges'
                        {...register('chargesApproved')}
                        value={amountCalc}
                        disabled
                      />
                    </div>
                    <div className='comment'>
                      {/* <label htmlFor=''>Amount Sent</label>
              <input type='number' className='charges-approved' {...register("chargesSent",{valueAsNumber:true})} required/> */}
                      <label htmlFor=''>Comment</label>
                      <input type='text' className='charges' />
                    </div>
                  </div>
                </div>
              </div>
              <button type='submit' className='btnn1'>
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  )
}

export default AdjustClaims
