import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-toastify/dist/ReactToastify.css";

import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "./claims.css";
import * as AiIcons from "react-icons/ai";
import Navbar from "../sidebar/Navbar";
import { getAllProviders } from "../functions/AutoComplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { calendarMonths } from "../months/Months";

import ViewClaims from "./ViewClaims";
import { ReloadContext } from "../context/reviewContext/ReviewInitialState";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { Row } from "../components/MUITableRows/Row";
import { TablePagination } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";

export default function Claims() {
  // TABLE ROWS TO LOAD
  const [pageSize, setPageSize] = useState(100);

  // MISCELLANEOUS
  const toastId = React.useRef(null);

  const [reloadClaimsListForInitiator, setReloadClaimsListForInitiator] =
    useState(false);

  const defaultColumns = [
    {
      field: "employeeName",
      headerName: "Name",
      width: 250,
      editable: false,
    },
    {
      field: "treatmentItems",
      headerName: "Treatment Items",
      width: 190,
      renderCell: (props) => {
        return <div>{props?.row?.claims?.length}</div>;
      },
    },
    {
      field: "chargesSent",
      headerName: "Charges Sent",
      width: 200,
      renderCell: (props) => {
        let chargesSent = props?.row?.claims
          .map((data) => data.chargesSent)
          .reduce((a, b) => a + b, 0)
          .toLocaleString("en-US");

        return <div>{chargesSent}</div>;
      },
    },
    {
      field: "chargesApproved",
      headerName: "Charges Approved",
      width: 200,
      renderCell: (props) => {
        let chargesApproved = props?.row?.claims
          .map((data) => data.chargesApproved)
          .reduce((a, b) => a + b, 0)
          .toLocaleString("en-US");

        return <div>{chargesApproved}</div>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (props) => {
        return (
          <button
            className="review-btn"
            onClick={() =>
              setData("notAction", props?.row, "verifierCompliance")
            }
          >
            <AiIcons.AiFillEye />
          </button>
        );
      },
    },
    {
      field: "approve",
      headerName: "Approve",
      width: 100,
      renderCell: (props) => {
        return (
          <button className="approveSingleClaimBtn" onClick={() => setEntity()}>
            <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>
          </button>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (props) => {
        return (
          <button className="btn-pending">
            {props?.row?.claims[0].claimsStatus}
          </button>
        );
      },
    },
  ];

  const customId = "custom-id-yes";
  const [claims, setClaims] = useState([]);

  const [year, setYear] = useState(null);
  const [date, setDate] = useState();

  const [allList, setAllList] = useState([]);

  const [startDate, setStartDate] = useState(new Date());

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedFilterParams, setSelectedFilterParams] = useState({
    selectedProvider: "",
    selectedMonth: "",
  });

  const [loading, setLoading] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [showClaimsList, setShowClaimsList] = useState(false);
  const [a, setA] = useState(null);
  let filtered;
  const d = new Date();
  let monthId;
  const [claimsIndex, setClaimsIndex] = useState(0);
  const [claimsData, setClaimsData] = useState(0);
  const Info = sessionStorage.getItem("fullName");
  const role = sessionStorage.getItem("role");
  const [loadingClaimsList, setLoadingClaimsList] = useState(false);
  const [loadingClaimsListErr, setLoadingClaimsListErr] = useState(false);
  const [loadingClaimsListErrMsg, setLoadingClaimsListErrMsg] = useState("");
  const [clickedSearch, setClickedSearch] = useState(false);
  const [claimTypeText, setClaimTypeText] = useState(
    sessionStorage.getItem("claimType") || "Choose Claim Type"
  );
  const claimTypeArray = ["NHIS", "Private", "All Claims", "plaschema"];
  const provideName = a
    ? a.provideName
    : sessionStorage.getItem("ProviderName");
  const selectedId = a ? a.idProvider : sessionStorage.getItem("providerId");
  const { reload, newAmount } = useContext(ReloadContext);

  // LOGIC FOR TABLE WHEN USER ROLE !==  INITIATOR
  // THE BELOW VARIABLE WAS SET TO FALSE IN THE CONFIRMATION DIALOGUE PAGE
  const [clickedOk, setClickedOk] = useState(false);

  // END OF LOGIC FOR TABLE WHEN USER ROLE !==  INITIATOR

  const providerSelect = useCallback(() => {
    const cancelToken = axios.CancelToken.source();

    if (
      claimTypeText === "Choose Claim Type" ||
      claimTypeText === "All Claims"
    ) {
      if (selectedId) {
        setLoadingClaimsList(true);
        setLoadingClaimsListErr(false);

        axios
          .get(
            `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=${role}&pageNumber=1&pageSize=50`,
            {
              cancelToken: cancelToken.token,
            }
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
          )
          .then((response) => {
            setClaims(response.data);
            setLoadingClaimsList(false);
            sessionStorage.setItem("clickedOk", false);
            setClickedOk(false);
          })

          .catch((error) => {
            console.error(error);
            setLoadingClaimsList(false);
            setLoadingClaimsListErr(true);
            setLoadingClaimsListErrMsg(
              error?.message || "Something went wrong, please try again"
            );
          });
        return () => {
          cancelToken.cancel();
        };
      }
    }
    if (
      claimTypeText === "NHIS" ||
      claimTypeText === "Private" ||
      claimTypeText === "plaschema"
    ) {
      if (selectedId) {
        setLoadingClaimsList(true);
        setLoadingClaimsListErr(false);
        axios
          .get(
            `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=${role}&pageNumber=1&pageSize=1000000&claimType=${claimTypeText?.toLocaleLowerCase()}`,
            {
              cancelToken: cancelToken.token,
            }
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
          )
          .then((response) => {
            setClaims(response.data);
            setLoadingClaimsList(false);
          })

          .catch((error) => {
            console.error(error);
            setLoadingClaimsList(false);
            setLoadingClaimsListErr(true);
            setLoadingClaimsListErrMsg(
              error?.message || "Something went wrong, please try again"
            );
          });
        return () => {
          cancelToken.cancel();
        };
      }
    }
  }, [date, selectedId]);

  const backClick = () => {
    // navigate.push('./pendingstatus')
    window.history.back();
  };
  // console.log(claims);

  const handleSetLife = (e, lifeType) => {};

  const datePicker = (e) => {
    if (selectedMonth && year) {
      monthId = calendarMonths.find(
        (singleCalendarMonth) => singleCalendarMonth.name === selectedMonth
      );
      setLoading(true);

      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${selectedId}&role=initiator&month=${monthId?.id}&year=${year}&pageSize=50&pageNumber=1`
        )
        .then((response) => {
          setClaims(response.data);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    }
  };

  const amount = claims
    ? claims.map((data) => data.claims.map((data) => data.chargesSent))
    : "";

  const charge = amount ? amount.flatMap((data) => data) : "";
  const total = [...charge];

  const amountPaid = total ? total.reduce((a, b) => a + b, 0) : "";

  // useEffect(() => {
  //   providerSelect()
  // }, [])
  useEffect(() => {
    // providerSelect()
  }, [errorPage]);

  // Function to get all claims as page loads
  const getAllClaims = () => {
    const cancelToken = axios.CancelToken.source();
    console.log("hello");
    if (
      claimTypeText === "Choose Claim Type" ||
      claimTypeText === "All Claims"
    ) {
      if (selectedId) {
        setLoadingClaimsList(true);
        setLoadingClaimsListErr(false);

        toastId.current = toast("Please wait...", {
          isLoading: true,
        });

        axios
          .get(
            `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=${role}&pageNumber=1&pageSize=50`,
            {
              cancelToken: cancelToken.token,
            }
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
          )
          .then((response) => {
            setClaims(response.data);
            setLoadingClaimsList(false);
            sessionStorage.setItem("clickedOk", false);
            setClickedOk(false);
            toast.update(toastId.current, {
              render: "Claim(s) fetched successfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
            });
            // toast.success('Claims fetched successfully', {
            //   toastId: customId,
            // })
          })

          .catch((error) => {
            console.error(error);
            setLoadingClaimsList(false);
            setLoadingClaimsListErr(true);
            setLoadingClaimsListErrMsg(
              error?.message || "Something went wrong, please try again"
            );
            toast.update(toastId.current, {
              render:
                error?.response?.data?.title ||
                error?.response?.data?.description ||
                error?.message ||
                "Something went wrong, please try again",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });
          });
        return () => {
          cancelToken.cancel();
        };
      }
    }
    if (
      claimTypeText === "NHIS" ||
      claimTypeText === "Private" ||
      claimTypeText === "plaschema"
    ) {
      if (selectedId) {
        setLoadingClaimsList(true);
        setLoadingClaimsListErr(false);
        axios
          .get(
            `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=${role}&pageNumber=1&pageSize=1000000&claimType=${claimTypeText?.toLocaleLowerCase()}`,
            {
              cancelToken: cancelToken.token,
            }
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
          )
          .then((response) => {
            setClaims(response.data);
            setLoadingClaimsList(false);
          })

          .catch((error) => {
            console.error(error);
            setLoadingClaimsList(false);
            setLoadingClaimsListErr(true);
            setLoadingClaimsListErrMsg(
              error?.message || "Something went wrong, please try again"
            );
          });
        return () => {
          cancelToken.cancel();
        };
      }
    }
  };
  // End of Function to get all claims as page loads

  useEffect(() => {
    getAllProviders(setAllList);
    getAllClaims();
  }, []);

  // useEffect(() => {
  //   providerSelect()
  // }, [a, reload])
  useEffect(() => {
    datePicker();
  }, [selectedMonth, year]);
  useEffect(() => {
    setData("action", claims[claimsIndex]);
  }, [claimsIndex]);

  useEffect(() => {
    console.log(loadingClaimsList);
  }, [loadingClaimsList]);

  const setData = (type, data, role) => {
    if (clickedSearch) {
      setClickedSearch(false);
    }
    setClaimsData(data);
    if (type !== "action") {
      if (role === "verifierCompliance") {
        if (showClaimsList === true) {
          return;
        } else {
          setShowClaimsList(true);
        }
      }
      if (role === "initiator") setShowClaimsList(!showClaimsList);
    }
    if (data) {
      let { claims, employeeName, employeeNumber, providerName } = data;
      localStorage.setItem("Claims", JSON.stringify(claims));
      localStorage.setItem("ProviderName", providerName);
      localStorage.setItem("employeeName", employeeName);
      localStorage.setItem("employeeNumber", employeeNumber);
    }
  };

  const handleSetClaimsIndex = (action, data) => {
    setData("action", data, "initiator");
    if (action === "prev" && claimsIndex > 0) {
      setClaimsIndex(claimsIndex - 1);
    }
    if (action === "next" && claimsIndex < claims.length - 1) {
      setClaimsIndex(claimsIndex + 1);
    }
  };

  const setEntity = () => {
    let data = [];
    let Claim = [];
    Claim.detail = claims[claimsIndex]?.claims;

    Claim.detail.forEach((element) => {
      let dataInfo = {
        approvedBy: Info,
        qty: element.qty,
        approvedQty: element.approvedQty,
        sno: element.sno,
        chargesApproved: element.chargesApproved,
        comment: element.comment,
      };
      data.push(dataInfo);
    });

    let answer = window.confirm("You are about to approve This Claim");
    if (data) {
      if (answer && role === "initiator") {
        toastId.current = toast("Please wait...", {
          isLoading: true,
        });
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/initiateclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then(() => {
            toast.update(toastId.current, {
              render: "Claim(s) succesfully approved",
              type: "success",
              isLoading: false,
              autoClose: 2500,
            });
            setTimeout(() => window.location.reload(), 1500);
          })
          .catch((error) => {
            toast.update(toastId.current, {
              render:
                error?.response?.data?.title ||
                error?.response?.data?.description ||
                error?.message ||
                "Something went wrong, please try again",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });

            console.error(error);
          });
      }
      if (answer && role === "verifier") {
        toastId.current = toast("Please wait...", {
          isLoading: true,
        });
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/verifyclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast.update(toastId.current, {
              render: "Claim(s) succesfully approved",
              type: "success",
              isLoading: false,
              autoClose: 2500,
            });
            setTimeout(() => window.location.reload(), 1500);
          })
          .catch((error) => {
            console.error(error);
            toast.update(toastId.current, {
              render:
                error?.response?.data?.title ||
                error?.response?.data?.description ||
                error?.message ||
                "Something went wrong, please try again",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });
          });
      }
      if (answer && role === "approver") {
        toastId.current = toast("Please wait...", {
          isLoading: true,
        });
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/approveclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then(() => {
            toast.update(toastId.current, {
              render: "Claim(s) succesfully approved",
              type: "success",
              isLoading: false,
              autoClose: 2500,
            });
            setTimeout(() => window.location.reload(), 1500);
          })
          .catch((error) => {
            console.error(error);
            toast.update(toastId.current, {
              render:
                error?.response?.data?.title ||
                error?.response?.data?.description ||
                error?.message ||
                "Something went wrong, please try again",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });
          });
      }
    }
  };

  // EFUNCTION TO APPROVE ALL CLAIMS
  const approveAll = () => {
    let data = [];

    claims.map((element) =>
      element.claims?.map((singleClaim) => {
        let dataInfo = {
          approvedBy: Info,
          sno: singleClaim.sno,
        };
        return data.push(dataInfo);
      })
    );

    let answer = window.confirm("You are about to approve This Claim");
    if (data) {
      if (answer && role === "verifier") {
        toastId.current = toast("Please wait...", {
          isLoading: true,
        });
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/verifyclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )

          .then(() => {
            toast.update(toastId.current, {
              render: "Claim(s) succesfully approved",
              type: "success",
              isLoading: false,
              autoClose: 2500,
            });
            setTimeout(() => window.location.reload(), 1500);
          })

          .catch((error) => {
            console.error(error);
            toast.update(toastId.current, {
              render:
                error?.response?.data?.title ||
                error?.response?.data?.description ||
                error?.message ||
                "Something went wrong, please try again",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });
          });
      }
      if (answer && role === "approver") {
        toastId.current = toast("Please wait...", {
          isLoading: true,
        });
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/approveclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )

          .then(() => {
            toast.update(toastId.current, {
              render: "Claim(s) succesfully approved",
              type: "success",
              isLoading: false,
              autoClose: 2500,
            });
            setTimeout(() => window.location.reload(), 1500);
          })

          .catch((error) => {
            toast.update(toastId.current, {
              render:
                error?.response?.data?.title ||
                error?.response?.data?.description ||
                error?.message ||
                "Something went wrong, please try again",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });

            console.error(error);
          });
      }
    }
  };

  const handleSetClaimType = (claimType) => {
    setClaimTypeText(claimType);
    sessionStorage.setItem("claimType", claimType);
  };

  const handleFilterClaims = (type) => {
    if (type === "Search") {
      setClickedSearch(true);
      const cancelToken = axios.CancelToken.source();
      const monthNumber = Number(startDate.toLocaleDateString().split("/")[0]);
      const yearNumber = Number(startDate.toLocaleDateString().split("/")[2]);

      if (selectedId && claimTypeText !== "All Claims") {
        setLoadingClaimsList(true);
        setLoadingClaimsListErr(false);

        console.log(selectedId);
        console.log(role);
        console.log(selectedId);
        console.log(monthNumber);
        console.log(yearNumber);
        console.log(claimTypeText);

        axios
          .get(
            `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${selectedId}&role=${role}&month=${monthNumber}&year=${yearNumber}&claimType=${claimTypeText.toLocaleLowerCase()}&pageSize=1000000&pageNumber=1`,
            {
              cancelToken: cancelToken.token,
            }
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
          )
          .then((response) => {
            console.log(response.data);
            setClaims(response.data);
            setLoadingClaimsList(false);
            // setClickedSearch(false)
          })
          .catch((err) => {
            // toast(err?.message)
            setLoadingClaimsList(false);
            setLoadingClaimsListErr(true);
            setLoadingClaimsListErrMsg(
              err?.message || "Something went wrong, please try again"
            );
          });

        return () => {
          cancelToken.cancel();
        };
      }
      if (selectedId && claimTypeText === "All Claims") {
        setLoadingClaimsList(true);
        setLoadingClaimsListErr(false);

        axios
          .get(
            `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${selectedId}&role=${role}&month=${monthNumber}&year=${yearNumber}&pageSize=1000000&pageNumber=1`,
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=${role}&year=${yearNumber}&pageSize=200&pageNumber=1`,
            {
              cancelToken: cancelToken.token,
            }
            // `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role={initiator}&pageNumber=1&pageSize=50`
          )
          .then((response) => {
            setClaims(response.data);
            setLoadingClaimsList(false);
            // setClickedSearch(false)
          })
          .catch((error) => {
            // toast(err?.message)
            setLoadingClaimsList(false);
            setLoadingClaimsListErr(true);
            setLoadingClaimsListErrMsg(
              error?.message || "Something went wrong, please try again"
            );
          });

        return () => {
          cancelToken.cancel();
        };
      }
    }

    if (type === "Reset") {
      const cancelToken = axios.CancelToken.source();
      const yearNumber = Number(startDate.toLocaleDateString().split("/")[2]);
      setLoadingClaimsList(true);
      setLoadingClaimsListErr(false);
      setClaimTypeText("All Claims");

      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=${role}&year=${yearNumber}&pageSize=10000&pageNumber=1`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          setClaims(response.data);
          setLoadingClaimsList(false);
          // setClickedSearch(false)
        })
        .catch((error) => {
          // toast(err?.message)
          setLoadingClaimsList(false);
          setLoadingClaimsListErr(true);
          setLoadingClaimsListErrMsg(
            error?.message || "Something went wrong, please try again"
          );
        });

      return () => {
        cancelToken.cancel();
      };
    }
  };

  useEffect(() => {}, [claimTypeText]);
  useEffect(() => {}, [clickedSearch]);

  useEffect(() => {
    if (
      claimsData?.employeeNumber &&
      (role === "verifier" || role === "approver")
    ) {
      setShowClaimsList(false);

      setTimeout(() => setShowClaimsList(true), 2000);
    }
    if (role === "initiator" && showClaimsList === true) {
      setShowClaimsList(false);

      setTimeout(() => setShowClaimsList(true), 2000);
    }
  }, [claims]);

  // LOGIC FOR TABLE WHEN USER ROLE !==  INITIATOR

  // HANDLE PAGE NAVIGATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let rows = [];
  function createData(
    name,
    treatmentItems,
    submittedDate,
    encounterDate,
    chargesSent,
    chargesApproved,
    // review,
    approve,
    status,
    singleClaim
  ) {
    return {
      name,
      treatmentItems,
      submittedDate,
      encounterDate,
      chargesSent,
      chargesApproved,
      // review,
      approve,
      status,
      singleClaim,
    };
  }

  claims.map((singleClaim) => {
    const { employeeName, claims } = singleClaim;
    return rows.push(
      createData(
        employeeName,
        claims.length,
        singleClaim.claims[0].authdate.substring(0, 10),
        singleClaim.claims[0].consultancyDate.substring(0, 10),
        singleClaim.claims
          .map((data) => data.chargesSent)
          .reduce((a, b) => a + b, 0)
          .toLocaleString("en-US"),
        singleClaim.claims
          .map((data) => data.chargesApproved)
          .reduce((a, b) => a + b, 0)
          .toLocaleString("en-US"),

        <button
          className="btn-reviews approveClaimIcon"
          onClick={() => setEntity()}
        >
          <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>
        </button>,
        <button className="btn-pending">
          {singleClaim.claims[0].claimsStatus}
        </button>,
        singleClaim
      )
    );
  });

  // USEEFFECT FOR AFTER CLAIM UPDATE
  useEffect(() => {
    providerSelect();
  }, [clickedOk, providerSelect]);

  // END OF LOGIC FOR TABLE WHEN USER ROLE !==  INITIATOR

  return (
    <>
      <ToastContainer />

      <div className="claimsWrapper">
        <Navbar />
        <div className="claimsRightWrapper">
          <div className="claimsRight">
            <div className="claimsFilterWrapper">
              <div onClick={backClick} className="backToPending">
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              {role === "initiator" && (
                <p className="claimAmount">
                  {claims?.length > 0
                    ? `Claims  ${claimsIndex + 1}  of ${claims.length}`
                    : ""}
                </p>
              )}

              <div className="dropdownWrapper">
                <Dropdown>
                  <Dropdown.Toggle>{claimTypeText}</Dropdown.Toggle>

                  <Dropdown.Menu>
                    {claimTypeArray.map((claimType) => {
                      return (
                        <Dropdown.Item
                          onClick={() => handleSetClaimType(claimType)}
                        >
                          {claimType}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="datePickerWrapper">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              </div>
              <div
                className="searchClaimsFilterBtn"
                onClick={() => handleFilterClaims("Search")}
              >
                Search
              </div>
              <div
                className="Reset"
                onClick={() => handleFilterClaims("Reset")}
              >
                Reset
              </div>
              <div className="navBtnWrapper">
                {role === "initiator" && (
                  <>
                    <div
                      className="previousClaim"
                      onClick={() =>
                        handleSetClaimsIndex("prev", claims[claimsIndex])
                      }
                    >
                      Previous
                    </div>
                    <div
                      className="nextClaim"
                      onClick={() =>
                        handleSetClaimsIndex("next", claims[claimsIndex])
                      }
                    >
                      Next
                    </div>
                  </>
                )}

                {(role === "verifier" || role === "approver") && (
                  <button
                    className="approveAllClaimsIcon"
                    onClick={() => approveAll()}
                    disabled={rows.length < 1}
                  >
                    Approve All
                  </button>
                )}
              </div>
              {}
            </div>
            <div className="styledTableWrapper">
              {role === "initiator" && (
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>EmployeeNo.</th>
                      <th>Diagnosis</th>
                      <th>Treatment items</th>
                      <th>Submitted Date</th>
                      <th>Encounter Date</th>
                      <th>Authorization Code</th>
                      <th>Charges Sent</th>

                      <th>Review</th>
                      <th>Approve</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {console.log(claims)}
                  {console.log(loadingClaimsList)}
                  {console.log(loadingClaimsListErr)}
                  {claims.length === 0 &&
                    !loadingClaimsList &&
                    !loadingClaimsListErr && (
                      <div className="noClaims">No claims available</div>
                    )}

                  {loadingClaimsList && (
                    <div className="noClaims">Loading claims...</div>
                  )}
                  {loadingClaimsListErr && (
                    <div className="noClaims">{loadingClaimsListErrMsg}</div>
                  )}
                  {claims.length > 0 &&
                  !loadingClaimsList &&
                  !loadingClaimsListErr ? (
                    <tbody className="">
                      {console.log(claims[claimsIndex]?.claims?.[0])}
                      <tr>
                        <td>{claims[claimsIndex]?.employeeName}</td>
                        <td>{claims[claimsIndex]?.employeeNumber}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {claims[claimsIndex]?.claims?.[0]?.diagnosis}
                        </td>
                        <td>{claims[claimsIndex]?.claims.length}</td>
                        <td>
                          {claims[claimsIndex]?.claims[0].authdate.substring(
                            0,
                            10
                          )}
                        </td>
                        <td>
                          {claims[
                            claimsIndex
                          ]?.claims[0].consultancyDate.substring(0, 10)}
                        </td>
                        <td>{claims[claimsIndex]?.claims[0]?.authorcode}</td>

                        <td>
                          {claims[claimsIndex]?.claims
                            .map((data) => data.chargesSent)
                            .reduce((a, b) => a + b, 0)
                            .toLocaleString("en-US")}
                        </td>
                        <td>
                          <button
                            className="btn-reviews"
                            onClick={() =>
                              setData(
                                "notAction",
                                claims[claimsIndex],
                                "initiator"
                              )
                            }
                          >
                            <AiIcons.AiFillEye />
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn-reviews approveClaimIcon"
                            onClick={() => setEntity()}
                          >
                            <FontAwesomeIcon
                              icon={faThumbsUp}
                            ></FontAwesomeIcon>
                          </button>
                        </td>
                        <td>
                          <button className="btn-pending">
                            {claims[claimsIndex]?.claims[0].claimsStatus}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <div className="noClaims"></div>
                  )}
                </table>
              )}
              {(role === "verifier" || role === "approver") && (
                <div className="datagridWrapper">
                  {/* <br />
                  <br /> */}
                  <DataGrid
                    key={loadingClaimsList}
                    rows={claims}
                    columns={defaultColumns}
                    pageSize={pageSize}
                    className="datagrid"
                    // checkboxSelection
                    // disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[100, 150, 200]}
                    // onRowClick={(row, e) => handleRowClick(row, e)}
                    pagination
                    getRowId={(row) =>
                      row?.employeeNumber +
                      new Date().getMilliseconds()?.toString()
                    }
                  />
                  {/* <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                    <Table aria-label='collapsible table'>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>Name</TableCell>
                          <TableCell align='right'>Treatment items</TableCell>
                          <TableCell align='right'>Submitted Date</TableCell>
                          <TableCell align='right'>Encounter Date</TableCell>
                          <TableCell align='right'>Charges Sent</TableCell>
                          <TableCell align='right'>Charges Approved</TableCell>
                          <TableCell align='right'>Approve</TableCell>
                          <TableCell align='right'>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.length > 0 ? (
                          rows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                              <Row
                                key={row.name + index}
                                row={row}
                                clickedOk={clickedOk}
                                setClickedOk={setClickedOk}
                              />
                            ))
                        ) : (
                          <div style={{ marginLeft: '10px' }}>
                            No Pending Claim
                          </div>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[2, 4, 6]}
                      component='div'
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer> */}
                </div>
              )}
            </div>
          </div>
          <div className="viewClaimsWrapper">
            {showClaimsList && !clickedSearch && (
              <ViewClaims
                claimsData={claimsData}
                claimTypeText={
                  sessionStorage.getItem("claimType") || claimTypeText
                }
                startDate={startDate}
                clickedSearch={clickedSearch}
                providerSelect={providerSelect}
                setReloadClaimsListForInitiator={
                  setReloadClaimsListForInitiator
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

