import {
  faEnvelopeCircleCheck,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FaIcons from 'react-icons/fa'

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dash',

    icon: <FaIcons.FaTachometerAlt />,
    cName: 'nav-text',
  },
  {
    title: 'Vet Claims ',
    path: '/pendingstatus',
    icon: <FontAwesomeIcon icon={faEnvelopeCircleCheck} />,
    cName: 'nav-text',
  },
  // {
  //   title: "Approve Claims",
  //   path: "/List",
  //   icon: <FaIcons.FaSearch />,
  //   cName: "nav-text",
  // },

  {
    title: 'Claims Status',
    path: '/claimsstatus',
    icon: <FontAwesomeIcon icon={faSpinner} />,
    cName: 'nav-text',
  },
]
