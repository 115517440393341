import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import "./list.css";
import toast, { Toaster } from "react-hot-toast";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import Navbar from "../../sidebar/Navbar";
import Filter from "../../components/filter/Filter";
import { calendarMonths } from "../../months/Months";
import Dropdown from "react-bootstrap/Dropdown";
import {
  autoComplete,
  handleChange,
  handleMonthChange,
  handleSelectedProvider,
  handleYearChange,
} from "../../functions/AutoComplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import * as AiIcons from "react-icons/ai";
import { ReloadContext } from "../../context/reviewContext/ReviewInitialState";

export default function Claims(props) {
  let navigate = useHistory();
  const [claims, setClaims] = useState([]);

  const [providerList, setProviderList] = useState([]);
  const [providerName, setProviderName] = useState("");
  const [addressName, setAddressName] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [allList, setAllList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filteredMonths, setFilteredMonths] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedFilterParams, setSelectedFilterParams] = useState({
    selectedProvider: "",
    selectedMonth: "",
  });
  const [filterList, setFilterList] = useState({
    providerList: false,
    monthList: false,
  });
  const [a, setA] = useState(null);
  const [claimsIndex, setClaimsIndex] = useState(0);
  const [review, setReview] = useState(false);
  const [reviewItem, setReviewItem] = useState(null);
  const [reviewInfo, setReviewInfo] = useState(null);

  const [updatedReviewInfo, setUpdatedReviewInfo] = useState({});

  const Info = sessionStorage.getItem("fullName");
  const provideName = sessionStorage.getItem("ProviderName");
  const selectedId = sessionStorage.getItem("providerId");
  let monthId;
  let filtered;
  const { reload, newAmount, dispatch } = useContext(ReloadContext);

  const datePicker = (e) => {
    // console.log(e.target.value);
    // const date = new Date(e.target.value)
    // const year = date.getFullYear()
    // setYear(year)
    // const month = date.getMonth() + 1
    // const nameOfMonth = date.toLocaleString('default', { month: 'long' })
    // setMonth(nameOfMonth)

    if (selectedMonth && year) {
      monthId = calendarMonths.find(
        (singleCalendarMonth) => singleCalendarMonth.name === selectedMonth
      );
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/filterclaims?IdProvider=${selectedId}&role=verifier&month=${monthId?.id}&year=${year}&pageSize=50&pageNumber=1`
        )
        .then((response) => {
          setClaims(response.data);
        })
        .catch((error) => console.error(error));
    }
  };

  const ClaimsList = claims
    .flatMap((data) => data.claims)
    .filter((data) => data.initiatedBy !== null)
    .map((data) => data.chargesSent);
  const approvedList = claims
    .flatMap((data) => data.claims)
    .filter((data) => data.initiatedBy !== null)
    .map((data) => data.chargesApproved);
  const approvedClaims = claims[claimsIndex]?.claims.flatMap((data) => {
    return data;
  });
  const providerBill = ClaimsList.reduce((a, b) => a + b, 0);
  const approvedBill = approvedList.reduce((a, b) => a + b, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const providerSelect = useCallback(() => {
    if (selectedId) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=verifier&pageNumber=1&pageSize=50`
        )
        .then((response) => {
          // console.log(response.data);
          setClaims(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const provider = providerList
      .filter((data) => data.idProvider === selectedId)
      .map((data) => data.providerName);
    setProviderName(...provider);
    const address = providerList
      .filter((data) => data.idProvider === selectedId)
      .map((data) => data.address01);
    setAddressName(...address);
  }, [providerList, selectedId]);

  const setEntity = () => {
    let data = [];
    let Claim = [];
    Claim.detail = approvedClaims;
    Claim.detail.forEach((element) => {
      let dataInfo = {
        approvedBy: Info,
        sno: element.sno,
      };
      data.push(dataInfo);
    });
    let updatedData = data.flatMap((data) => data);
    let answer = window.confirm("You are about to approve This Claim");
    if (data) {
      if (answer) {
        axios
          .post(
            `https://portalapi.lifeworthhmo.com/api/Claims/verifyclaim`,
            data,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast("Approved Succesfully", {
              style: {
                borderRadius: "10px",
                background: "#F8A370",
                color: "#fff",
              },
            });
          })
          .then(() => {
            fetchList();
          })
          .then(() => window.location.reload())
          .catch((error) => console.error(error));
      }
    }
  };

  const fetchList = () => {
    axios
      .get(
        `https://portalapi.lifeworthhmo.com/api/Claims/pendingclaims?IdProvider=${selectedId}&role=verifier&pageNumber=1&pageSize=50&claimType=private`
      )
      .then((response) => {
        // console.log(response.data);
        setClaims(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSetClaimsIndex = (action) => {
    if (action === "prev" && claimsIndex > 0) {
      setClaimsIndex(claimsIndex - 1);
    }
    if (action === "next" && claimsIndex < claims.length - 1) {
      setClaimsIndex(claimsIndex + 1);
    }
  };
  // const verifiedList1 = claims.filter((data)=>data.verifiedBy === null)
  // console.log(verifiedList1);

  // const verifiedList2 = claims.filter((data)=>data.verifiedBy !== null)

  // const amount = name === Info && verifiedList1 ? verifiedList1.map((data)=>data.chargesApproved) : verifiedList2.map((data)=>data.chargesApproved)
  // const amountPaid = amount? amount.reduce((a,b)=>a+b ,0) : ""
  // console.log(amountPaid);

  useEffect(() => {
    providerSelect();
  }, [providerSelect]);

  useEffect(() => {
    providerSelect();
  }, [a]);
  useEffect(() => {
    // handleSetClaimsIndex()
  }, [claimsIndex]);

  useEffect(() => {
    datePicker();
  }, [selectedMonth, year]);

  const backClick = () => {
    navigate.push("./dash");
  };

  const reviewData = (data) => {
    setReview(true);
    setReviewInfo(data);
    setUpdatedReviewInfo(data);
    setReviewItem(data);
    let {
      sno,
      authdate,
      description,
      classification,
      diagnosis,
      employeeName,
      employeeNo,
      authorcode,
      consultancyDate,
      qty,
      chargesSent,
      month,
      type,
      protype,
      year,
      day,
    } = data;
    sessionStorage.setItem("ID", sno);
    sessionStorage.setItem("authdate", authdate);
    sessionStorage.setItem("Description", description);
    sessionStorage.setItem("classification", classification);
    sessionStorage.setItem("diagnosis", diagnosis);
    sessionStorage.setItem("employeeName", employeeName);
    sessionStorage.setItem("employeeNo", employeeNo);
    sessionStorage.setItem("authorcode", authorcode);
    sessionStorage.setItem("consultancyDate", consultancyDate);
    sessionStorage.setItem("qty", qty);
    sessionStorage.setItem("chargesSent", chargesSent);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("day", day);
    sessionStorage.setItem("year", year);
    sessionStorage.setItem("protype", protype);
    sessionStorage.setItem("type", type);
  };
  const handleSubmitReview = () => {
    let answer = window.confirm("You have are about to update a claim");

    if (updatedReviewInfo) {
      if (answer) {
        axios
          .put(
            `https://portalapi.lifeworthhmo.com/api/Claims/${reviewInfo?.sno}`,
            { ...updatedReviewInfo, claimType: "private" }
          )
          .then((response) => console.debug(response))
          .then(() => {
            toast("Reviewed Succesfully", {
              style: {
                borderRadius: "10px",
                background: "#F8A370",
                color: "#fff",
              },
            });
          })
          .then(() => {
            setReview(false);
            fetchList();
          })
          .then(() => {
            dispatch({
              type: "RELOAD",
              payload: reloadPage,
            });
          })
          .catch((error) => console.error(error));
      }
    }
  };

  const handleSetReview = () => {
    setReview(false);
  };

  const handleSetCharges = (e, type) => {
    if (type === "quantity") {
      // setReviewedQuantity(e.target.value)
      setUpdatedReviewInfo({
        ...updatedReviewInfo,
        approvedQty: Number(e.target.value),
      });
    }
    if (type === "charges") {
      // setReviewedPrice(e.target.value)
      setUpdatedReviewInfo({
        ...updatedReviewInfo,
        chargesApproved: Number(e.target.value),
      });
    }
  };

  const [reloadPage, setReloadPage] = useState(false);

  return (
    <>
      <div className="viewClaimsWrapper">
        {/* <Navbar /> */}
        <div className="viewClaimsRight">
          {/* <div className='claimsFilterWrapper'> */}
          {/* <div onClick={backClick} className='backToPending'>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <p className='claimAmount'>
              {claims?.length > 0
                ? `Claims  ${claimsIndex + 1}  of ${claims.length}`
                : 'No claims available'}
            </p> */}

          {/* <div className="monthsFilterWrapper">
              <div className="monthsFilter">
                <input
                  onClick={() =>
                    autoComplete("month", setFilterList, filterList)
                  }
                  placeholder="Month"
                  type="text"
                  className="monthsFilterInput"
                  onChange={(e) =>
                    handleMonthChange(
                      e,
                      filtered,
                      calendarMonths,
                      setFilteredMonths,
                      setSelectedMonth,
                      setFilterList,
                      filterList
                    )
                  }
                  value={selectedMonth}
                />
              </div>
              {filterList.monthList && (
                <div className="monthsList">
                  {
                    filteredMonths.length > 0 ? (
                      filteredMonths.map((singleMonth, index) => {
                        return (
                          <div
                            className="monthsListItem"
                            key={index}
                            onClick={(e) =>
                              handleSelectedProvider(
                                "month",
                                e,
                                setSelectedMonth,
                                setMonth,
                                filteredMonths,
                                setSelectedFilterParams,
                                selectedFilterParams,
                                setFilterList
                              )
                            }
                          >
                            {singleMonth.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="inputMonthName">Input month</div>
                    )
                    // {calen}
                  }
                </div>
              )}
            </div>
            <div className="yearInputWrapper">
              <input
                type="number"
                className="yearInput"
                placeholder="year"
                value={year ? year : null}
                onChange={(e) => handleYearChange(e, setYear)}
              />
            </div>
            <div
              className="showAll"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear filter
            </div> */}
          {/* <div className='navBtnWrapper'>
              <div
                className='previousClaim'
                onClick={() => handleSetClaimsIndex('prev')}
              >
                Previous
              </div>
              <div
                className='nextClaim'
                onClick={() => handleSetClaimsIndex('next')}
              >
                Next
              </div>
            </div> */}
          {/* </div> */}
          {/* <button onClick={backClick} className='bck'>
            Back to Dashboard
          </button>
          <div className='date-dropdown'>
            <label className='label'> Filter by Date:</label>
            <input type='Date' className='date' onSelect={datePicker}></input>
            <Toaster position='top-center' reverseOrder={false} />
            <label className='label'>Provider: </label>
            <input className='dropdown' value={provideName} disabled></input>
          </div>
          <div className='bills-approval'>
            <div>
              <h2>
                PAYEE:<span className='detail-txt'> {provideName}</span>
              </h2>
              <h2>
                PERIOD:<span className='detail-txt'>{`${month}, ${year}`}</span>
              </h2>
            </div>
            <button className='approve-btn' onClick={setEntity}>
              Approve
            </button>
          </div> */}

          <div className="styledTableWrapper">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Treatment Items</th>
                  <th>Submitted Qty</th>
                  <th>Submitted Bill</th>
                  <th>Payable Qty</th>
                  <th>Payable Bill</th>
                  <th>Initiated By</th>
                  <th>Review</th>
                </tr>
              </thead>
              <tbody>
                {claims.length === 0 && (
                  <div className="noClaims">No claims available</div>
                )}
                {claims[claimsIndex]?.claims.map((claim) => {
                  return (
                    <tr>
                      <td>{claim.diagnosis}</td>
                      <td>{claim.qty}</td>
                      <td>{claim.chargesSent}</td>
                      {/* <td>{claim.approvedQty ? claim.approvedQty : 0}</td> */}
                      <td>
                        {review ? (
                          reviewItem?.sno === claim.sno ? (
                            <input
                              type="number"
                              onChange={(e) => handleSetCharges(e, "quantity")}
                              className="reviewInput"
                              min={0}
                            />
                          ) : (
                            claim.approvedQty
                          )
                        ) : (
                          claim.approvedQty
                        )}
                      </td>
                      {/* <td>{claim.chargesApproved}</td> */}
                      <td>
                        {review ? (
                          reviewItem?.sno === claim.sno ? (
                            <input
                              type="number"
                              onChange={(e) => handleSetCharges(e, "charges")}
                              className="reviewInput"
                              min={0}
                            />
                          ) : (
                            claim.chargesApproved
                          )
                        ) : (
                          claim.chargesApproved
                        )}
                      </td>
                      <td>{claim.initiatedBy}</td>

                      <td>
                        {/* <Link to='/adjustclaims'> */}
                        {review ? (
                          reviewItem?.sno === claim.sno ? (
                            <div className="reviewBtns">
                              <div
                                className="cancelReview"
                                onClick={handleSetReview}
                              >
                                Cancel
                              </div>
                              <div
                                className="approveReview"
                                onClick={handleSubmitReview}
                              >
                                Done
                              </div>
                            </div>
                          ) : (
                            <button
                              className="claims-btn-review"
                              onClick={() => reviewData(claim)}
                            >
                              Review
                            </button>
                          )
                        ) : (
                          <button
                            className="claims-btn-review"
                            onClick={() => reviewData(claim)}
                          >
                            Review
                          </button>
                        )}

                        {/* </Link> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <table className="styled-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Diagnosis</th>

                  <th>Provider Bill</th>
                  <th>Qty Submitted</th>
                  <th>Qty Approved</th>
                  <th>Approved Amount</th>
                  <th> Total Provider Amount</th>
                  <th> Total Approved Amount</th>
                  <th>Initiated By</th>
                  <th>Verified By</th>
                  <th>Compliance</th>
                </tr>
              </thead>
              {claims.map((data, index) => (
                <tbody className="">
                  {data.claims.length < 2 ? (
                    <tr key={index}>
                      <td>{data.claims[0].consultancyDate.substring(0, 10)}</td>
                      <td>{data.employeeName}</td>
                      <td>
                        {data.claims.map((data) => (
                          <h6>
                            {data.diagnosis}
                            <br />
                          </h6>
                        ))}
                      </td>

                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.chargesSent}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.qty}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.approvedQty ? data.approvedQty : 0}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.chargesApproved}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        <h4>
                          {data.claims
                            .map((data) => data.chargesSent)
                            .reduce((a, b) => a + b, 0)}
                        </h4>
                      </td>
                      <td>
                        <h4>
                          {data.claims
                            .map((data) => data.chargesApproved)
                            .reduce((a, b) => a + b, 0)}
                        </h4>
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h5>
                            {data.initiatedBy}
                            <br />
                          </h5>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h6>
                            {data.verifiedBy}
                            <br />
                          </h6>
                        ))}
                      </td>
                      <td></td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td>{data.claims[0].consultancyDate.substring(0, 10)}</td>
                      <td>{data.employeeName}</td>

                      <td>
                        {data.claims.map((data) => (
                          <h6>
                            {data.diagnosis}
                            <br />
                          </h6>
                        ))}
                      </td>

                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.chargesSent}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.qty}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.approvedQty ? data.approvedQty : 0}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h4>
                            {data.chargesApproved}
                            <br />
                          </h4>
                        ))}
                      </td>
                      <td>
                        <h4>
                          {data.claims
                            .map((data) => data.chargesSent)
                            .reduce((a, b) => a + b, 0)}
                        </h4>
                      </td>
                      <td>
                        <h4>
                          {data.claims
                            .map((data) => data.chargesApproved)
                            .reduce((a, b) => a + b, 0)}
                        </h4>
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h5>
                            {data.initiatedBy}
                            <br />
                          </h5>
                        ))}
                      </td>
                      <td>
                        {data.claims.map((data) => (
                          <h6>
                            {data.verifiedBy}
                            <br />
                          </h6>
                        ))}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              ))}
            </table> */}
          </div>
          <div className="total-due">
            <div className="totalWrapper">
              <h2 className="total-amt-list">
                Total Provider Bill:{" "}
                <NumberFormat
                  value={providerBill}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
              <h2 className="total-amt-list">
                Total Approved Amount:{" "}
                <NumberFormat
                  value={approvedBill}
                  displayType={"text"}
                  className="amt"
                  thousandSeparator={true}
                  prefix={"NGN"}
                />
              </h2>
            </div>
            <button
              className="approve-btn-list"
              onClick={setEntity}
              disabled={!claims.length > 0}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
