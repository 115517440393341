import React, { useState, useEffect } from "react";
import axios from "axios";
import "./approveclaim.css";
import toast, { Toaster } from "react-hot-toast";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";

export default function ApproveClaim() {
  let navigate = useHistory();
  const [claims, setClaims] = useState([]);
  const [id, setId] = useState(0);
  const [providerList, setProviderList] = useState([]);

  const providerSelect = (e) => {
    const providerId = Number(e.target.value);
    setId(providerId);
    if (providerId) {
      axios
        .get(
          `http://15.237.160.238:50/api/Claims/pendingclaims?IdProvider=${providerId}&role=approver&pageNumber=1&pageSize=50`
        )
        .then((response) => {
          setClaims(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchList = () => {
    axios
      .get(
        `http://15.237.160.238:50/api/Claims/pendingclaims?IdProvider=${id}&role=approver&pageNumber=1&pageSize=50`
      )
      .then((response) => {
        setClaims(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const backClick = () => {
    navigate.push("./dash");
  };

  const amount = claims.map((data) => data.chargesApproved);
  const amountPaid = amount ? amount.reduce((a, b) => a + b, 0) : "";

  useEffect(() => {
    axios
      .get(`http://15.237.160.238:50/api/Provider?pageSize=700&pageNumber=1`)
      .then((response) => {
        setProviderList(response.data.result);
      })
      .then(() => {
        toast("Providers fetched Succesfully", {
          style: {
            borderRadius: "10px",
            background: "#F8A370",
            color: "#fff",
          },
        });
      })
      .catch((error) => console.error(error));
  }, []);

  //   const setData = (data) => {
  //     let { sno, authdate, description,classification ,diagnosis,employeeName,employeeNo,authorcode,consultancyDate,qty } = data;
  //     localStorage.setItem("ID", sno);
  //     localStorage.setItem("authdate", authdate);
  //     localStorage.setItem("Description", description);
  //     localStorage.setItem("classification", classification);
  //     localStorage.setItem("diagnosis", diagnosis);
  //     localStorage.setItem("employeeName", employeeName);
  //     localStorage.setItem("employeeNo", employeeNo);
  //     localStorage.setItem("authorcode", authorcode);
  //     localStorage.setItem("consultancyDate", consultancyDate);
  //     localStorage.setItem("qty", qty);
  //   }

  const setEntity = (data) => {
    let Claim = {};
    Claim = data;
    Claim.approvedBy = "osho";
    // Claim.qty = data.qty
    // Claim.chargesApproved = data.chargesApproved
    Claim.sno = data.sno;

    if (Claim) {
      axios
        .post(`http://15.237.160.238:50/api/Claims/approveclaim`, Claim, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          toast("Approved Succesfully", {
            style: {
              borderRadius: "10px",
              background: "#F8A370",
              color: "#fff",
            },
          });
        })
        .then(() => {
          fetchList();
        })
        .catch((error) => console.log(error));
    }
  };

  //   const setEntity2 =(data)=>{
  //     let Claim = {}
  //     Claim = data
  //     Claim.approvedBy = "douglas"
  //     // Claim.qty = data.qty
  //     // Claim.chargesApproved = data.chargesApproved
  //     Claim.sno = data.sno
  //     Claim.verifiedBy2 = "douglas"
  //     console.log(Claim);
  //     if(Claim){
  //       axios.post(`http://15.237.160.238:50/api/Claims/verifyclaim`,
  //       Claim,
  //       { headers: { 'Content-Type': 'application/json' }})
  //       .then((response)=> console.log(response))
  //       .then(()=>{
  //         toast("Approved Succesfully", {
  //           style: {
  //           borderRadius: '10px',
  //           background: '#F8A370',
  //           color: '#fff',
  //        },
  //         },
  //         )

  //       })
  //       .then(()=>{
  //         fetchList()
  //       })
  //       .catch((error)=>console.log(error))
  //     }
  //   }

  return (
    <>
      <div>
        <button onClick={backClick} className="bck">
          Back to Dashboard
        </button>
        <div className="date-dropdown">
          {/* <label className="label-spacing" > Filter by Date:</label>
        <input type="Date" className="date" onSelect={datePicker}></input> */}
          <Toaster position="top-center" reverseOrder={false} />
          <label className="label-spacing">Providers List </label>
          <select className="dropdownn" onClick={providerSelect}>
            {providerList
              ? providerList.map((data, index) => (
                  <option key={index} value={data.idProvider}>
                    {data.providerName}
                  </option>
                ))
              : ""}
          </select>
        </div>
        <div className="bills-approval">
          <h1>
            Total amount of Verified Bills:{" "}
            <NumberFormat
              value={amountPaid}
              displayType={"text"}
              className="amt"
              thousandSeparator={true}
              prefix={"NGN"}
            />
          </h1>
          {/* <button className="btn-approval">Approve All</button> */}
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>EmployeeNo.</th>
                <th>Diagnosis</th>
                <th>Classification</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Charges Sent</th>
                <th>Charges Approved</th>
                <th>Approval</th>
              </tr>
            </thead>
            {claims.map((data, index) => (
              <tbody className="">
                <tr key={console.log(data)}>
                  <td>{data.employeeName}</td>
                  <td>{data.employeeNo}</td>
                  <td>{data.diagnosis}</td>
                  <td>{data.classification}</td>
                  <td>{data.description}</td>
                  <td>{data.qty}</td>
                  <td>{data.chargesSent}</td>
                  <td>{data.chargesApproved}</td>
                  <td>
                    <button
                      className="btn-approve"
                      onClick={() => setEntity(data)}
                    >
                      Approve
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}
