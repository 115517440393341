import Page from './login/Page'
import Dashboard from './Dashboard/Dashboard'
import Claims from './Claims/Claims'
import AdjustClaims from './Claims/AdjustClaims'
import Verification from './login/PasswordVerification/Verification'
import SearchEnrollee from './Dashboard/SearchEnrollee/SearchEnrollee'
import List from './Claims/Claimslist/List'
import ApproveClaim from './Claims/Claimslist/ApproveClaim'
import ViewClaims from './Claims/ViewClaims'
import ViewList from './Claims/Claimslist/ViewList'
import FinanceList from './Claims/Claimslist/FinanceList'
import ClaimsStatus from './Claims/ClaimsStatus'
import Pendingclaims from './Claims/Claimslist/Pendingclaims'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import Invoice from './pages/invoice/Invoice'

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(
    sessionStorage.getItem('userId')
  )

  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Page} />
        <Route path='/Verification' component={Verification} />
        <Route path='/dash' component={Dashboard} />
        <Route path='/claims' component={Claims} />
        <Route path='/adjustclaims' component={AdjustClaims} />
        <Route path='/viewclaims' component={ViewClaims} />
        <Route exact path='/SearchEnrollee' component={SearchEnrollee} />
        <Route exact path='/List' component={List} />
        <Route exact path='/approveclaim' component={ApproveClaim} />
        <Route exact path='/viewlist' component={ViewList} />
        <Route exact path='/financelist' component={FinanceList} />
        <Route path='/claimsstatus' component={ClaimsStatus} />
        <Route path='/pendingstatus' component={Pendingclaims} />
        <Route path='/invoice' component={Invoice} />
      </Switch>
    </Router>
  )
  // return userLoggedIn ? (
  //   <Router>
  //     <Switch>
  //       <Route path='/' exact component={Page} />
  //       <Route path='/Verification' component={Verification} />
  //       <Route exact path='/dash' component={Dashboard} />
  //       <Route path='/claims' component={Claims} />
  //       <Route path='/adjustclaims' component={AdjustClaims} />
  //       <Route path='/viewclaims' component={ViewClaims} />
  //       <Route exact path='/SearchEnrollee' component={SearchEnrollee} />
  //       <Route exact path='/List' component={List} />
  //       <Route exact path='/approveclaim' component={ApproveClaim} />
  //       <Route exact path='/viewlist' component={ViewList} />
  //       <Route exact path='/financelist' component={FinanceList} />
  //       <Route path='/claimsstatus' component={ClaimsStatus} />
  //       <Route path='/pendingstatus' component={Pendingclaims} />
  //     </Switch>
  //   </Router>
  // ) : (
  //   <Router>
  //     <Redirect to='/' />
  //     <Route path='/' exact component={Page} />
  //   </Router>
  // )
}

export default App
