import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import "./index.css";

import "../node_modules/font-awesome/css/font-awesome.min.css";
import { ReloadContextProvider } from "./context/reviewContext/ReviewInitialState";

ReactDom.render(
  <ReloadContextProvider>
    <App />
  </ReloadContextProvider>,
  document.getElementById("root")
);
