import React, { useState, useEffect } from "react";
import axios from "axios";
import "./financeList.css";
import toast, { Toaster } from "react-hot-toast";
import NumberFormat from "react-number-format";
import Navbar from "../../sidebar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faArrowLeft,
  faCheck,
  faMarker,
} from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "react-datepicker";
import SimpleBackdrop from "../../components/backdrop/Backdrop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import NonAsyncSelect from "../../components/select/NonAsyncSelect";
import AlertDialog from "../../components/dialogue/Dialogue";
import { ToastContainer } from "react-toastify";

export default function FinanceList() {
  const [providerList, setProviderList] = useState([]);
  const [data, setData] = useState();
  const [currentClaim, setCurrentClaim] = useState({});

  const [loadingBillList, setLoadingBillList] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const nameOfMonth = startDate.toLocaleString("default", { month: "long" });
  const monthNumber = Number(startDate.toLocaleDateString().split("/")[0]);
  const yearNumber = Number(startDate.toLocaleDateString().split("/")[2]);

  // DATA FOR STATE MANAGEMENT AFTER CLAIM HAS BEEN UPDATED
  const [updatedPaymentStatus, setUpdatedPaymentStatus] = useState(false);

  // DATA FOR FILTERING BILL LIST/CLAIM LIST
  const [selectedClaimType, setSelectedClaimType] = useState(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
  let claimsToBeDisplayed;

  // DATA FOR CLAIM TYPE
  const claimType = [
    {
      label: "All",
    },
    { label: "private" },
    {
      label: "nhis",
    },
    {
      label: "plaschema",
    },
  ];

  // DATA FOR CLAIM STATUS

  const claimsStatus = [
    {
      label: "All",
    },
    { label: "paid" },
    {
      label: "Not paid",
    },
  ];

  // BACKDROP FUNCTIONALITIES
  const [open, setOpen] = React.useState(false);

  // DIALOGUE FUNCTIONALITIES
  const [openDialogue, setOpenDialogue] = React.useState(false);

  // UTILITY FUNCTIONS

  // FUNCTION TO GET AND SET THE DATA PROP
  const getAndSetData = (currentData) => {
    const cancelToken = axios.CancelToken.source();

    axios
      .get(
        `https://portalapi.lifeworthhmo.com/api/Claims/bill?claimType=${currentData?.claimType}&role=accountant&month=${monthNumber}&year=${yearNumber}&IdProvider=${currentData?.idProvider}`,
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((response) => {
        setData(response.data);
        // console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      cancelToken.cancel();
    };
  };

  // FILTER FUNCTIONS

  const filterClaims = (billList) => {
    if (selectedClaimType && selectedPaymentStatus) {
      claimsToBeDisplayed = billList?.filter((bill) => {
        let filtCri = bill.paymentStatus === null ? "Not paid" : "paid";

        return (
          selectedPaymentStatus === filtCri &&
          selectedClaimType === bill.claimType
        );
      });
    }
    if (
      selectedPaymentStatus &&
      (selectedClaimType === "All" || !selectedClaimType)
    ) {
      claimsToBeDisplayed = billList?.filter((bill) => {
        let filtCri = bill.paymentStatus === null ? "Not paid" : "paid";

        return selectedPaymentStatus === filtCri;
      });
    }
    if (
      selectedClaimType &&
      (selectedPaymentStatus === "All" || !selectedPaymentStatus)
    ) {
      claimsToBeDisplayed = billList?.filter((bill) => {
        return selectedClaimType === bill.claimType;
      });
    }
    if (
      (!selectedClaimType || selectedClaimType === "All") &&
      (!selectedPaymentStatus || selectedPaymentStatus === "All")
    ) {
      claimsToBeDisplayed = billList;
    }
  };

  // DIALOGUE FUNCTIONS
  const handleOpenDialogue = (currentData) => {
    setCurrentClaim(currentData);
    setOpenDialogue(true);
    getAndSetData(currentData);
  };

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  // BACKDROP FUNCTIONS
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = (currentData) => {
    // SHOW MODAL
    setOpen(!open);
    getAndSetData(currentData);

    // GET INFO OF CURRENT DATA
  };

  //
  useEffect(() => {
    const date = new Date();
    // const year = date.getFullYear()
    // const month = date.getMonth() + 1
    setLoadingBillList(true);
    const cancelToken = axios.CancelToken.source();
    axios
      .get(
        `https://portalapi.lifeworthhmo.com/api/Claims/provider-bill?role=accountant&month=${monthNumber}&year=${yearNumber}`,
        {
          cancelToken: cancelToken.token,
        }
        // `https://portalapi.lifeworthhmo.com/api/Claims/provider-bill?role=accountant&month=${month}&year=${year}`
      )
      .then((response) => {
        // setProviderList(response.data)
        filterClaims(response.data);
        setLoadingBillList(false);
      })
      .then(() => {
        console.log(claimsToBeDisplayed);
        setProviderList(claimsToBeDisplayed);
      })
      .catch((error) => console.error(error));

    return () => {
      cancelToken.cancel();
    };
  }, [
    monthNumber,
    yearNumber,
    selectedClaimType,
    selectedPaymentStatus,
    updatedPaymentStatus,
  ]);

  const provideId = providerList.map((data) => data.idProvider);
  // console.log(provideId);

  const backClick = () => {
    window.history.back();
  };

  const datePicker = (e) => {
    // console.log(e.target.value);
    const date = new Date(e.target.value);
    const year = date.getFullYear();
    setYear(year);
    const month = date.getMonth() + 1;
    const nameOfMonth = date.toLocaleString("default", { month: "long" });
    setMonth(nameOfMonth);
    if (date && month) {
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims/provider-bill?role=accountant&month=${month}&year=${year}`
        )
        .then((response) => {
          // console.log(response.data);
          setProviderList(response.data);
        })
        .then(() => {
          toast("Amount fetched Succesfully", {
            style: {
              borderRadius: "10px",
              background: "#F8A370",
              color: "#fff",
            },
          });
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {}, [loadingBillList]);

  // USE EFFECT FOR SETTING DATA PROP
  useEffect(() => {}, [data]);

  // USE EFFECT FOR SETTING DATA PROP
  useEffect(() => {}, [data]);

  return (
    <div className="claimsWrapper">
      <ToastContainer />
      <AlertDialog
        open={openDialogue}
        handleClose={handleCloseDialogue}
        data={data}
        setUpdatedPaymentStatus={setUpdatedPaymentStatus}
        updatedPaymentStatus={updatedPaymentStatus}
        currentClaim={currentClaim}
      />
      <Navbar />
      {/* <SimpleBackdrop open={open} handleClose={handleClose} data={data} /> */}
      <div className="claimsRightWrapper">
        <div className="claimsRight">
          {/* <div className='claimsFilterWrapper'> */}
          {/* <div onClick={backClick} className='backToPending'>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className='datePickerWrapperFinance'>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat='MM/yyyy'
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
            <div className='periodWrapper'>
              <div className='period'>
                PERIOD:
                <span className='detail-txt'>{`${nameOfMonth}   ${yearNumber}`}</span>
              </div>
            </div> */}
          <Accordion sx={{ width: 900, marginBottom: 5 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter Claims</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filterWrapper">
                <NonAsyncSelect
                  title="Filter by status"
                  list={claimsStatus}
                  setSelectedPaymentStatus={setSelectedPaymentStatus}
                  setSelectedClaimType={setSelectedClaimType}
                />
                <NonAsyncSelect
                  title="Filter by claim type"
                  list={claimType}
                  setSelectedClaimType={setSelectedClaimType}
                  setSelectedPaymentStatus={setSelectedPaymentStatus}
                />
                <div className="yearPickerWrapper">
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                  {/* <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearDropdown
                    dateFormatCalendar='MMMM'
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    className='yearPicker'
                  /> */}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          {/* <div className='toas'>
              <div>
                <Toaster position='top-center' reverseOrder={false} />
                <label className='label'> Filter by Date:</label>
                <input type='Date' className='' onSelect={datePicker}></input>
              </div>
              <h2>
                PERIOD:<span className='detail-txt'>{`${month}, ${year}`}</span>
              </h2>
            </div> */}
          {/* </div> */}
          <div className="styledTableWrapper">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Provider List</th>
                  <th>Claim Type</th>
                  <th>Amount(NGN)</th>
                  <th>Action</th>
                  <th>Payment Status</th>
                  <th>Payment Action</th>
                </tr>
              </thead>
              {loadingBillList && (
                <div className="noClaims">Loading bill list...</div>
              )}
              {!loadingBillList && providerList.length === 0 && (
                <div className="noClaims">No available bills.</div>
              )}
              <tbody>
                {providerList
                  ? providerList.map((providerListData) => (
                      <tr>
                        <td>{providerListData.provider}</td>
                        <td className="claimTypeData">
                          {providerListData.claimType}
                        </td>
                        <td>
                          <NumberFormat
                            value={providerListData.amount}
                            displayType={"text"}
                            prefix="NGN "
                            className="billAmount"
                            thousandSeparator={true}
                            suffix=".00"
                          ></NumberFormat>
                        </td>
                        <td>
                          <Link
                            // target='_blank'
                            to={{
                              pathname: `/invoice?claimType=${providerListData?.claimType}&role=accountant&month=${monthNumber}&year=${yearNumber}&IdProvider=${providerListData?.idProvider}&providerName=${providerListData.provider}`,
                            }}
                            target="_blank"
                          >
                            <button
                              className="viewInvoiceBtn"
                              // onClick={() => handleToggle(data)}
                            >
                              View Invoice
                            </button>
                          </Link>
                        </td>
                        <td>
                          {/* {console.log(data)} */}
                          {providerListData.paymentStatus === null ? (
                            <button className="viewInvoiceBtn notPaid">
                              Not Paid
                            </button>
                          ) : (
                            <button className="viewInvoiceBtn paid">
                              Paid
                              <span style={{ marginLeft: "15px" }}>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            </button>
                          )}
                        </td>
                        <td>
                          <button
                            className="viewInvoiceBtn markAs"
                            onClick={() => handleOpenDialogue(providerListData)}
                            disabled={providerListData.paymentStatus}
                          >
                            Mark as Paid
                          </button>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

