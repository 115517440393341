import * as FaIcons from 'react-icons/fa'

export const ApproveBarData = [
  {
    title: 'Dashboard',
    path: '/dash',

    icon: <FaIcons.FaTachometerAlt />,
    cName: 'nav-text',
  },
  //   {
  //     title: "Vet Claims",
  //     path: "/Claims",
  //     icon: <FaIcons.FaRegBell />,
  //     cName: "nav-text",
  //   },
  {
    title: 'Approve Claims',
    path: '/pendingstatus',
    icon: <FaIcons.FaSearch />,
    cName: 'nav-text',
  },
]
