import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import FinanceDownloadPage from '../downloadPages/FinanceDownloadPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faX } from '@fortawesome/free-solid-svg-icons'
import jsPDF from 'jspdf'

export default function SimpleBackdrop(props) {
  const { open, handleClose, data } = props

  const generatePDF = () => {
    const report = new jsPDF('p', 'px', [1600, 1250])
    report.html(document.querySelector('#report')).then(() => {
      report.save('report.pdf')
    })
  }

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99 }}
        open={open}
      >
        <div
          className='cancelBackdropBtn'
          style={{
            position: 'absolute',
            top: 20,
            right: 130,
            fontSize: '12px',
            color: 'white',
            cursor: 'pointer',
            height: '30px',
            width: '30px',
            background: 'rgb(167, 7, 7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
          }}
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faX} />
        </div>
        <div
          onClick={generatePDF}
          className='downloadBtn'
          style={{
            position: 'absolute',
            top: 20,
            right: 180,
            fontSize: '14px',
            color: 'white',
            cursor: 'pointer',
            background: 'black',
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          Download
          <span>
            <FontAwesomeIcon
              icon={faArrowDown}
              className='backdropCancelIcon'
              style={{ marginLeft: '5px' }}
            />
          </span>
        </div>
        {/* <CircularProgress color='inherit' /> */}
        <FinanceDownloadPage data={data} generatePDF={generatePDF} />
      </Backdrop>
    </div>
  )
}
