import React, { useReducer } from 'react'
import { ReloadReducer } from './ReloadReducer'

const INITIAL_STATE = {
  reload: false,
  newAmount: null,
  claimsListUniversal: [{}],
}

export const ReloadContext = React.createContext(INITIAL_STATE)

export const ReloadContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReloadReducer, INITIAL_STATE)

  return (
    <ReloadContext.Provider
      value={{
        reload: state.reload,
        newAmount: state.newAmount,
        claimsListUniversal: state.claimsListUniversal,
        dispatch,
      }}
    >
      {children}
    </ReloadContext.Provider>
  )
}
