export const calendarMonths = [
  { name: 'JAN', id: 1 },
  { name: 'FEB', id: 2 },
  { name: 'MARCH', id: 3 },
  { name: 'APRIL', id: 4 },
  { name: 'MAY', id: 5 },
  { name: 'JUNE', id: 6 },
  { name: 'JULY', id: 7 },
  { name: 'AUG', id: 8 },
  { name: 'SEP', id: 9 },
  { name: 'OCT', id: 10 },
  { name: 'NOV', id: 11 },
  { name: 'DEC', id: 12 },
]
