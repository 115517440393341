import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import './Claimslist/list.css'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer, toast } from 'react-toastify'

import { useHistory } from 'react-router'
import Navbar from '../sidebar/Navbar'
import {
  autoComplete,
  getAllProviders,
  handleChange,
  handleLoading,
  handleMonthChange,
  handleSelectedProvider,
  handleYearChange,
} from '../functions/AutoComplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFilter } from '@fortawesome/free-solid-svg-icons'
import { calendarMonths } from '../months/Months'
import Loading from '../components/loading/Loading'
import Error from '../components/error/Error'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { Tooltip } from '@mui/material'

export default function ClaimsStatus() {
  // MISCELLANEOUS
  const toastId = React.useRef(null)

  let navigate = useHistory()
  const [claims, setClaims] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [dateClicked, setDateClicked] = useState(false)
  const [filteredClaims, setFilteredClaims] = useState([])
  const [id, setId] = useState(0)
  const [providerList, setProviderList] = useState([])
  const [filterList, setFilterList] = useState({
    providerList: false,
    monthList: false,
  })
  const [allList, setAllList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [selectedProvider, setSelectedProvider] = useState('')
  const [selectedFilterParams, setSelectedFilterParams] = useState({
    selectedProvider: '',
    selectedMonth: '',
  })
  const [a, setA] = useState(null)
  const [providerName, setProviderName] = useState('')
  const [addressName, setAddressName] = useState('')
  let filtered
  const [filteredMonths, setFilteredMonths] = useState([])
  const [selectedMonth, setSelectedMonth] = useState('')
  const [month, setMonth] = useState()
  const [year, setYear] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorPage, setErrorPage] = useState(false)
  const [filterText, setTilterText] = useState('')

  let monthId
  // TABLE ROWS TO LOAD
  const [pageSize, setPageSize] = useState(100)

  const provideName = a ? a.provideName : sessionStorage.getItem('ProviderName')
  const selectedId = a ? a.idProvider : sessionStorage.getItem('providerId')

  const defaultColumns = [
    {
      field: 'employeeName',
      headerName: 'Name',
      width: 250,
      editable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'diagnosis',
      headerName: 'Diagnosis',
      width: 190,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    // {
    //   field: 'treatmentItems',
    //   headerName: 'Treatment Items',
    //   width: 190,
    //   renderCell: (props) => {
    //     return <div>{props?.row?.claims?.length}</div>
    //   },
    // },
    // {
    //   field: 'treatmentItems',
    //   headerName: 'Treatment Items',
    //   width: 190,
    //   renderCell: (props) => {
    //     return <div>{props?.row?.claims?.length}</div>
    //   },
    // },
    {
      field: 'chargesSent',
      headerName: 'Charges Sent',
      width: 200,
    },
    {
      field: 'chargesApproved',
      headerName: 'Charges Approved',
      width: 200,
    },

    {
      field: 'claimsStatus',
      headerName: 'Status',
      width: 130,
    },
    {
      field: 'employeeNo',
      headerName: 'Enrolee Id',
      width: 130,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Service',
      width: 130,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'qty',
      headerName: 'Qty Submitted',
      width: 130,
    },
    {
      field: 'approvedQty',
      headerName: 'Qty Approved',
      width: 130,
    },
    {
      field: 'initiatedBy',
      headerName: 'Initiated By',
      width: 130,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'verifiedBy',
      headerName: 'Verified By',
      width: 130,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'approvedBy',
      headerName: 'Compliance',
      width: 130,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className='csutable-cell-trucate'>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'consultancy Date',
      headerName: 'Encounter Date',
      width: 130,
      valueGetter: (params) => {
        return params?.row
      },
      valueFormatter: (params) => {
        const refinedDate = new Date(params?.value?.consultancyDate)
        const dateWithRightFormat = format(refinedDate, 'dd-MMM-yyyy')
        return dateWithRightFormat
      },
    },

    {
      field: 'dateSubmitted',
      headerName: 'Date Submitted',
      width: 130,
      valueGetter: (params) => {
        return params?.row
      },
      valueFormatter: (params) => {
        const refinedDate = new Date(params?.value?.authdate)
        const dateWithRightFormat = format(refinedDate, 'dd-MMM-yyyy')
        return dateWithRightFormat
      },
    },
    {
      field: 'Date Initiated',
      headerName: 'Date Initiated',
      width: 130,
      valueGetter: (params) => {
        return params?.row
      },
      valueFormatter: (params) => {
        const refinedDate = new Date(params?.value?.dateInitiated)
        const dateWithRightFormat = format(refinedDate, 'dd-MMM-yyyy')
        return dateWithRightFormat
      },
    },
    {
      field: 'verifiedDate',
      headerName: 'Date Verified',
      width: 130,
      valueGetter: (params) => {
        return params?.row
      },
      valueFormatter: (params) => {
        const refinedDate = new Date(params?.value?.dateVerified)
        const dateWithRightFormat = format(refinedDate, 'dd-MMM-yyyy')
        return dateWithRightFormat
      },
    },
    {
      field: 'approvedDate',
      headerName: 'Date Verified',
      width: 130,
      valueGetter: (params) => {
        return params?.row
      },
      valueFormatter: (params) => {
        const refinedDate = new Date(params?.value?.dateApproved)
        const dateWithRightFormat = format(refinedDate, 'dd-MMM-yyyy')
        return dateWithRightFormat
      },
    },
  ]

  // console.log(sessionStorage.getItem("providerId"));

  //   const datePicker = (e)=> {
  //     console.log(e.target.value);
  //     const date = new Date(e.target.value)
  //     const year = date.getFullYear()
  //     setYear(year)
  //     const month = date.getMonth() + 1
  //     const nameOfMonth = date.toLocaleString(
  //       'default', {month: 'long'}
  //     );
  //     setMonth(nameOfMonth);
  //     if (date && month){
  //       axios.get(`http://15.237.160.238:50/api/Claims/filterclaims?IdProvider=${id}&role=verifier&month=${month}&year=${year}&pageSize=50&pageNumber=1`)
  //       .then((response)=>{
  //         console.log(response.data)
  //         setClaims(response.data);
  //       })
  //       .catch((error)=>console.log(error))
  //     }

  //   }

  // const datePicker = useCallback(
  //   (e) => {
  //     const monthNumber = Number(startDate.toLocaleDateString().split('/')[0])
  //     const yearNumber = Number(startDate.toLocaleDateString().split('/')[2])

  //     const fil = claims?.filter(
  //       (claim) => claim?.month === monthNumber && claim?.year === yearNumber
  //     )

  //     setFilteredClaims(fil)
  //   },
  //   [startDate]
  // )

  const providerSelect = (e) => {
    const cancelToken = axios.CancelToken.source()

    // datePicker()
    // console.log(a);

    const providerId = Number(a?.idProvider)
    setId(providerId)
    if (providerId) {
      toastId.current = toast('Please wait...', {
        isLoading: true,
      })
      axios
        .get(
          `https://portalapi.lifeworthhmo.com/api/Claims?IdProvider=${providerId}&pageNumber=1&pageSize=10000`,
          {
            cancelToken: cancelToken.token,
          }
        )
        .then((response) => {
          toast.update(toastId.current, {
            render: 'Claim(s) found!',
            type: 'success',
            isLoading: false,
            autoClose: 2500,
          })
          setClaims(response.data)
          setFilteredClaims(response.data)
        })
        .catch((error) => {
          console.error(error)
          toast.update(toastId.current, {
            render:
              error?.response?.data?.title ||
              error?.response?.data?.description ||
              error?.message ||
              'Something went wrong, please try again',
            type: 'error',
            isLoading: false,
            autoClose: 2500,
          })
        })
      return () => {
        cancelToken.cancel()
      }
    }
    const provider = providerList
      .filter((data) => data.idProvider === providerId)
      .map((data) => data.providerName)
    setProviderName(...provider)
    const address = providerList
      .filter((data) => data.idProvider === providerId)
      .map((data) => data.address01)
    setAddressName(...address)
  }

  // useEffect(() => {
  //   setLoading(true)
  //   const cancelToken = axios.CancelToken.source()

  //   axios
  //     .get(
  //       `https://portalapi.lifeworthhmo.com/api/Provider?pageSize=1000&pageNumber=1`,
  //       { cancelToken: cancelToken.token }
  //     )
  //     .then((response) => {
  //       console.log('a')
  //       setProviderList(response.data.result)
  //       setErrorPage(false)
  //       setLoading(false)
  //     })

  //     .catch((error) => {
  //       console.error(error)
  //     })

  //   return () => {
  //     cancelToken.cancel()
  //   }
  // }, [id])

  // useEffect(() => {
  //   datePicker()
  // }, [selectedMonth, year, claims])
  // FUNCTION TO HANDLE INPUT CHANGES
  const handleDateChange = (event, name, data) => {
    if (name === 'date') {
      setStartDate(data)
      setDateClicked((prev) => !prev)
    }
  }
  // END OF FUNCTION TO HANDLE INPUT CHANGES

  // FUNCTION TO CONVERT DATE TO THE CORRECT FORMAT
  function convertDateFormat(localeString) {
    const dateObj = new Date(localeString)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1)
    return { month: Number(month), year }
  }
  // END OF FUNCTION TO CONVERT DATE TO THE CORRECT FORMAT

  useEffect(() => {
    // EXTRACT MONTH AND YEAR NUMBERS
    const monthNumber = Number(startDate.toLocaleDateString().split('/')[0])
    const yearNumber = Number(startDate.toLocaleDateString().split('/')[2])

    const filteredMonthClaims = claims?.filter((claim) => {
      const monthToFilterWith = convertDateFormat(claim?.consultancyDate)?.month
      const yearToFilterWith = convertDateFormat(claim?.consultancyDate)?.year

      return (
        monthToFilterWith === monthNumber && yearToFilterWith === yearNumber
      )
    })
    setFilteredClaims(filteredMonthClaims)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateClicked])

  useEffect(() => {
    const cachedData = sessionStorage.getItem('providers')
    if (cachedData) {
      setAllList(JSON.parse(cachedData))
      setFilteredList(JSON.parse(cachedData))
    } else {
      getAllProviders(setAllList, setFilteredList)
    }
  }, [])
  // useEffect(() => {
  //   getAllProviders(setAllList)
  // }, [filterList])
  useEffect(() => {
    providerSelect()
  }, [a])
  // useEffect(() => {
  //   datePicker()
  // }, [datePicker])

  const backClick = () => {
    window.history.back()
    // navigate.push('./dash')
  }

  return (
    <div className='claimsStatusWrapper'>
      <ToastContainer />
      <Navbar />
      {/* {loading && handleLoading(setLoading)} */}
      {/* {loading && <Loading />} */}
      <div className='claimsStatusRight'>
        <div className='claimsStatusFilterWrapper'>
          <div onClick={backClick} className='backToPending'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div className='claimsStatusFilter'>
            <input
              type='text'
              className='claimsStatusFilterInput'
              placeholder='Filter by provider'
              onClick={() =>
                autoComplete('provider', setFilterList, filterList)
              }
              onChange={(e) =>
                handleChange(
                  e,
                  filtered,
                  allList,
                  setFilteredList,
                  setSelectedProvider,
                  filterList,
                  setFilterList
                )
              }
              value={selectedProvider}
            />
            <span>
              <FontAwesomeIcon icon={faFilter} />
            </span>
          </div>
          {filterList.providerList && (
            <div className='FilteredProviderList'>
              {!loading ? (
                filteredList.length > 0 ? (
                  filteredList.map((singleItem, index) => {
                    return (
                      <div
                        className='FilteredProviderListItem'
                        key={index}
                        onClick={(e) =>
                          handleSelectedProvider(
                            'provider',
                            e,
                            setSelectedProvider,
                            setA,
                            filteredList,
                            setSelectedFilterParams,
                            selectedFilterParams,
                            setFilterList
                          )
                        }
                      >
                        {singleItem.providerName}
                      </div>
                    )
                  })
                ) : (
                  <div>Please input a valid provider name</div>
                )
              ) : (
                'Loading providers list...'
              )}
            </div>
          )}
          <div className='monthsFilterWrapper'>
            <DatePicker
              selected={startDate}
              onChange={(date, e) => handleDateChange(e, 'date', date)}
              dateFormat='MM/yyyy'
              showMonthYearPicker
              showFullMonthYearPicker
            />
            {/* <div className='monthsFilter'>
              <input
                onClick={() => autoComplete('month', setFilterList, filterList)}
                placeholder='Month'
                type='text'
                className='monthsFilterInput'
                onChange={(e) =>
                  handleMonthChange(
                    e,
                    filtered,
                    calendarMonths,
                    setFilteredMonths,
                    setSelectedMonth,
                    setFilterList,
                    filterList
                  )
                }
                value={selectedMonth}
              />
            </div>
            {filterList.monthList && (
              <div className='monthsList'>
                {
                  filteredMonths.length > 0 ? (
                    filteredMonths.map((singleMonth, index) => {
                      return (
                        <div
                          className='monthsListItem'
                          key={index}
                          onClick={(e) =>
                            handleSelectedProvider(
                              'month',
                              e,
                              setSelectedMonth,
                              setMonth,
                              filteredMonths,
                              setSelectedFilterParams,
                              selectedFilterParams,
                              setFilterList
                            )
                          }
                        >
                          {singleMonth.name}
                        </div>
                      )
                    })
                  ) : (
                    <div className='inputMonthName'>Input month</div>
                  )
                  // {calen}
                }
              </div>
            )} */}
          </div>
          {/* <div className='yearInputWrapper'>
            <input
              type='number'
              className='yearInput'
              placeholder='Year'
              value={year ? year : null}
              onChange={(e) => handleYearChange(e, setYear)}
            />
          </div> */}
          <div
            className='showAll'
            onClick={() => {
              window.location.reload()
            }}
          >
            Reset
          </div>
        </div>
        <div className='claimsStatusStyledTableWrapper'>
          <DataGrid
            rows={filteredClaims}
            columns={defaultColumns}
            pageSize={pageSize}
            className='claimsStatusdatagrid'
            // checkboxSelection
            disableSelectionOnClick
            // experimentalFeatures={{ newEditingApi: true }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[100, 150, 200]}
            // onRowClick={(row, e) => handleRowClick(row, e)}
            pagination
            getRowId={(row) =>
              row?.employeeNo +
              new Date().getMilliseconds().toString() +
              row?.formno +
              Math.random()
            }
            sx={{ overflow: 'scroll' }}
          />
          {/* <table className='claimsStatusStyledTable'>
            <thead>
              <tr>
                <th>Encounter Date</th>
                <th>Submission Date</th>
                <th>Patient ID</th>
                <th>Name</th>
                <th>Diagnosis</th>
                <th>Service</th>
                <th>Qty Submitted</th>
                <th>Provider Bill</th>
                <th>Qty Approved</th>
                <th>Approved Amount</th>
                <th>Initiated By</th>
                <th>Verified By</th>
                <th>Compliance</th>
              </tr>
            </thead>
            {filteredClaims.length === 0 && (
              <div className='noClaims'>No Claims Available</div>
            )}
            {console.log(claims)}
            {console.log(filteredClaims)}
            {claims.length > 0
              ? (filteredClaims ? filteredClaims : claims).map(
                  (data, index) => (
                    <tbody className='' key={index}>
                      <tr key={index}>
                        <td>
                          {data.consultancyDate.substring(0, 10)}
                   
                        </td>
                        <td>
                          {data.authdate.substring(0, 10)}
                       
                        </td>
                        <td>{data.employeeNo}</td>
                        <td>{data.employeeName}</td>
                        <td>{data.diagnosis}</td>
                        <td>{data.description}</td>
                        <td>{data.claims ? data.claims[0].qty : data.qty}</td>
                        <td>{data.chargesSent}</td>
                        <td>{data.approvedQty}</td>
                        <td>
                          {data.claims
                            ? data.claims[0].chargesApproved
                            : data.chargesApproved}
                        </td>
                        <td>{data.initiatedBy}</td>
                        <td>{data.verifiedBy}</td>
                        <td>{data.approvedBy}</td>
                      </tr>
                    </tbody>
                  )
                )
              : 
                ''}
          </table> */}
        </div>
      </div>

      {/* <div>
        <button onClick={backClick} className='bck'>
          Back to Dashboard
        </button>
        <div className='date-dropdown'>
          <Toaster position='top-center' reverseOrder={false} />
          <label className='label'>List of Providers: </label>
          <select className='dropdown' onClick={providerSelect}>
            {providerList
              ? providerList.map((data, index) => (
                  <option key={index} value={data.idProvider}>
                    {data.providerName}
                  </option>
                ))
              : ''}
          </select>
        </div>
        <div className='bills-approval'>
          <div>
            <h2>
              PAYEE:<span className='detail-txt'> {providerName}</span>
            </h2>
            <h2>
              ADDRESS:<span className='detail-txt'>{addressName}</span>
            </h2>
          </div>
        </div>
        <div>
          <table className='table'>
            <thead>
              <tr>
                <th>Encounter Date</th>
                <th>Submission Date</th>
                <th>Patient ID</th>
                <th>Name</th>
                <th>Diagnosis</th>
                <th>Service</th>
                <th>Qty Submitted</th>
                <th>Provider Bill</th>
                <th>Qty Approved</th>
                <th>Approved Amount</th>
                <th>Initiated By</th>
                <th>Verified By</th>
                <th>Compliance</th>
              </tr>
            </thead>
            {claims.map((data, index) => (
              <tbody className=''>
                <tr key={index}>
                  <td>{data.consultancyDate.substring(0, 10)}</td>
                  <td>{data.authdate.substring(0, 10)}</td>
                  <td>{data.employeeNo}</td>
                  <td>{data.employeeName}</td>
                  <td>{data.diagnosis}</td>
                  <td>{data.description}</td>
                  <td>{data.qty}</td>
                  <td>{data.chargesSent}</td>
                  <td>{data.approvedQty}</td>
                  <td>{data.chargesApproved}</td>
                  <td>{data.initiatedBy}</td>
                  <td>{data.verifiedBy}</td>
                  <td>{data.approvedBy}</td>
                </tr>
              </tbody>
            ))}
          </table> */}
      {/* <div className="total-due">
          <h2 className="total-amt">Total Provider Bill: <NumberFormat value={providerBill}  displayType={'text'} className="amt" thousandSeparator={true} prefix={'NGN'} /></h2>
          <h2 className="total-amt">Total Approved Amount: <NumberFormat value={approvedBill}  displayType={'text'} className="amt" thousandSeparator={true} prefix={'NGN'} /></h2>
        </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  )
}
