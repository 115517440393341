import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faSpinner,
  faEnvelopeCircleCheck,
} from '@fortawesome/free-solid-svg-icons'
import './main.css'
import note from './notes.png'
import Id from './id-card.png'
import claims from './refund.png'
import bcgImg from './background.jpg'
import axios from 'axios'
import lwnpm from '../Dashboard/lifeworth-logo.png'
import lwMiniLogo from '../Dashboard/LWlogo2.png'
import Loading from '../components/loading/Loading'
import Error from '../components/error/Error'

function Main() {
  const [data, setData] = useState('')
  const [dependant] = useState('')

  const Info = sessionStorage.getItem('fullName')
  const role = sessionStorage.getItem('role')
  let link
  let cardTitle

  if (role === 'initiator') {
    link = '/pendingstatus'
    cardTitle = 'Vet claims'
  } else if (role === 'accountant') {
    link = '/financelist'
    cardTitle = 'Total bill amount'
  } else if (role === 'approver') {
    link = '/pendingstatus'
    cardTitle = 'Approve claims'
  } else if (role === 'verifier') {
    link = '/pendingstatus'
    cardTitle = 'Verify claims'
  } else {
    link = '/pendingstatus'
    cardTitle = 'Vet claims'
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    axios
      .get(
        `https://portalapi.lifeworthhmo.com/api/Claims/pending-hospital-count?role=${role}`,
        { cancelToken: cancelToken.token }
      )
      .then((response) => {
        setData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })

    return () => {
      cancelToken.cancel()
    }
  }, [role])

  return (
    <>
      {/* <img
        src='https://images.pexels.com/photos/1028599/pexels-photo-1028599.jpeg?auto=compress&cs=tinysrgb&w=800'
        alt='background'
        className='bcgImg'
      /> */}
      {/* <Loading /> */}
      {/* <Error /> */}
      <div className='background'>
        <img src={lwMiniLogo} alt='' className='lwLogo1' />
        <img src={lwMiniLogo} alt='' className='lwLogo2' />
        <img src={lwMiniLogo} alt='' className='lwLogo3' />

        <div className='mainContent'>
          <div className='mainContentTop'>
            <div className='companyName'>
              {/* <img src={lwMiniLogo} alt='' className='lwLogo' /> */}
              {/* <img src={lwMiniLogo} alt='' className='lwLogo2' /> */}
              {/* <img src={lwLogo} alt='' className='lwLogo' /> */}
              {/* Lifeworth <span className='hmo'>HMO</span> */}
            </div>
          </div>
          <div className='userName'>
            Welcome <span>{Info?.split(' ')[0].toLocaleUpperCase()}</span>
          </div>
          <div className='cards'>
            <Link to={link}>
              <div className='card'>
                <div className='cardTop'>
                  <FontAwesomeIcon
                    icon={faEnvelopeCircleCheck}
                    className='cardImg'
                  />
                </div>
                <div className='cardTitle'>{cardTitle}</div>
              </div>
            </Link>

            <Link to={'/claimsstatus'}>
              <div className='card'>
                <div className='cardTop'>
                  <FontAwesomeIcon icon={faSpinner} className='cardImg' />
                </div>
                <div className='cardTitle'>Check claims status</div>
              </div>
            </Link>
            <div className='card'>
              <div className='cardTop'>
                <FontAwesomeIcon icon={faEnvelope} className='cardImg' />
              </div>
              <div className='cardTitle'>Unvetted claims</div>
              {data.count > 0 ? (
                <div className='vetCount vetCount0'>{data.count}</div>
              ) : (
                <div className=' vetCount '>{data.count}</div>
              )}
            </div>

            <div className='card'>
              <div className='cardTop'>
                {dependant.totalCount ? (
                  dependant.totalCount
                ) : (
                  <img src={Id} alt='' className='cardImg' />
                )}
              </div>
              <div className='cardTitle'>Total Number of Principals</div>
            </div>
          </div>
        </div>
      </div>
      {/*       
       
        
      <div className='mainwrapper'>
          <h1 className = "msg">WELCOME {Info.toLocaleUpperCase()} </h1>
       <div className="mains">
        <div className='sub-mainwrapper1'>
          <div className='wrapper1'>
            <div className='sub-wrapper1'>
              <p className='itenaryy'>
               Unvetted claims
              </p>
              <br />
              <Image style  />
            </div>
            <h3 className='item-quantity'>{data.count}</h3>
          </div>
          
          {(()=>{
            if (role === "initiator"){
              return (
                <Link to = "/pendingstatus">
                <div className='wrapper1' href ="/claims" >
                  <div className='sub-wrapper1'>
                    <p className='itenaryy'>
                      Vet Claims
                    </p>
                    <br />
                    <Image4 />
                  </div>
                  
                </div>
                </Link>
              )
            } else if (role === "accountant"){
              return (
                <Link to = "/financelist">
                <div className='wrapper1' href ="/financelist" >
                  <div className='sub-wrapper1'>
                    <p className='itenaryy'>
                      Total Bill Amount
                    </p>
                    <br />
                    <Image4 />
                  </div>
                  
                </div>
                </Link>
              )
            }else if(role === "approver") {
              return(
                <Link to ='/pendingstatus'>
                <div className='wrapper1'>
                  <div className='sub-wrapper1'>
                    <p className='itenaryy'>
                      Verify Claims 
                    </p>
                    <br />
                    <Imagee />
                  </div>
                  
                </div>
                </Link>
             
              )
            }else {
              return(
                <Link to ='/pendingstatus'>
                <div className='wrapper1'>
                  <div className='sub-wrapper1'>
                    <p className='itenaryy'>
                      Verify Claims 
                    </p>
                    <br />
                    <Imagee />
                  </div>
                  
                </div>
                </Link>
             
              )
            }
          })()
          }
          
          
          
        </div>
        
        <div className='sub-mainwrapper2'>
          <Link to ='/claimsstatus'>
          <div className='wrapper2'>
            <div className='sub-wrapper2'>
              <p className='itenaryy'>
                Check Claims Status
              </p>
              <br />
              <Image2 />
            </div>
            
          </div>
          </Link>
          <div className='wrapper2'>
            <div className='sub-wrapper2'>
              <p className='itenaryy'>
                Total Number of Principals
              </p>
              <br />
              <Image2 />
            </div>
            <h3 className='item-quantity'>{dependant.totalCount}</h3>
          </div>
          
        </div>
        </div>
      </div> */}
    </>
  )
}

const Image = () => {
  return <img src={note} alt='' className='img2' />
}

const Imagee = () => {
  return <img src={note} alt='' className='img3' />
}

const Image2 = () => {
  return <img src={Id} alt='' className='img4' />
}

const Image4 = () => {
  return <img src={claims} alt='' className='img6' />
}

export default Main
