// import React, { Component, useContext } from 'react'
// import { PostData } from '../../src/services/PostData'
// import { Redirect } from 'react-router-dom'
// import * as FaIcons from 'react-icons/fa'
// import medi from './medicalimg.jpg'
// import './login.css'
// import RingLoader from 'react-spinners/ClipLoader'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
// import { ToastContainer, toast } from 'react-toastify'

// class Page extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       userId: '',
//       password: '',
//       redirect: false,
//       error: null,
//       post: false,
//       data: '',
//     }
//     this.Login = this.Login.bind(this)
//     this.togglePassword = this.togglePassword.bind(this)
//     this.onChange = this.onChange.bind(this)
//   }

//   togglePassword(e) {
//     this.setState({ toggle: !this.state.toggle })
//   }

//   Login(e) {
//     localStorage.clear()
//     this.setState({ post: true })
//     // eslint-disable-next-line react/no-direct-mutation-state
//     this.state.post = setTimeout(() => {
//       this.setState({ post: false })
//     }, 8000)
//     e.preventDefault()
//     if (this.state.userId && this.state.password) {
//       PostData('Admin', this.state).then((result) => {
//         let responseJSON = result

//         // console.log(result);
//         if (responseJSON.userId) {
//           sessionStorage.setItem('fullName', responseJSON.fullName)
//           sessionStorage.setItem('userId', responseJSON.userId)
//           sessionStorage.setItem('role', responseJSON.role)
//           this.setState({ redirect: true })
//         } else {
//           console.log(responseJSON)
//           toast.error(responseJSON?.detail)
//           this.setState({ error: 'Wrong Login details !' })
//           this.setState({ data: result })
//         }
//         //  const info = this.state.data.details
//         //  const {word} = this.state.post
//         //  console.log(word);
//       })
//     }
//   }

//   onChange(e) {
//     this.setState({ [e.target.name]: e.target.value })
//     // console.log(this.state);
//   }

//   render() {
//     if (this.state.redirect) {
//       return <Redirect to={'/dash'} />
//     }

//     // if(sessionStorage.getItem("")){
//     //   return(<Redirect to ={'/dash'} />)
//     // }

//     return (
//       <>
//         <ToastContainer />
//         <section className='form'>
//           <Images />
//           <div className='formm'>
//             {this.state.post ? <RingLoader></RingLoader> : ``}
//             <form>
//               <span className='er'>{this.state.error} </span>

//               <h2>USER ID</h2>
//               <label htmlFor='email'></label>
//               <input
//                 type='text'
//                 name='userId'
//                 placeholder='Email'
//                 className='emailInput'
//                 onChange={this.onChange}
//               />
//               <h2>PASSWORD</h2>
//               <div className='password'>
//                 <label htmlFor='password'></label>
//                 <input
//                   type={this.state.toggle ? 'text' : 'password'}
//                   placeholder='Password'
//                   name='password'
//                   className='passwordInput'
//                   onChange={this.onChange}
//                 />
//                 {this.state.toggle ? (
//                   <FontAwesomeIcon
//                     icon={faEye}
//                     onClick={this.togglePassword}
//                     className='togglePassword'
//                   />
//                 ) : (
//                   <FontAwesomeIcon
//                     icon={faEyeSlash}
//                     onClick={this.togglePassword}
//                     className='togglePassword'
//                   />
//                 )}
//                 {/* <FaIcons.FaRegEyeSlash
//       onClick={this.togglePassword}
//       className='togglePassword'
//     /> */}
//               </div>

//               <button type='submit' className='btn1' onClick={this.Login}>
//                 Login
//               </button>

//               {/* <p className='desc'>
//       Dont have an account yet ?
//       <Modal />
//     </p> */}
//             </form>
//           </div>
//         </section>
//       </>
//     )
//   }
// }
// const Images = () => {
//   return <img src={medi} alt='' className='img' />
// }

// export default Page

import React, { useState } from 'react'
import './login.css'
import Img1 from '../assets/images/8812179 1.png'
import Img2 from '../assets/images/8812189 1.png'
import Img3 from '../assets/images/8812233 1.png'
import { BsEye, BsFillEyeSlashFill } from 'react-icons/bs'
import axios from 'axios'
import { Redirect, useHistory } from 'react-router-dom'
import { BASE_URL } from '../services/PostData'
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import lifeworthLogo from '../assets/images/lifeworthLogo.png'

import 'react-toastify/dist/ReactToastify.css'
import { useRef } from 'react'

const Page = () => {
  // MISCELLANEOUS
  const toastId = useRef(null)
  let history = useHistory()

  // END OF MISCELLANEOUS

  // PROCESSING LOGIN (LOADING)
  const [loading, setLoading] = useState(false)

  // USER LOGIN DETAILS
  const [user, setUser] = useState({
    userId: '',
    password: '',
  })

  // PASSWORD VISIBILITY
  const [showPassword, setShowPassword] = useState(false)

  // FUNCTION FOR PASSWORD TOGGLE
  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev)
  }

  // END OF FUNCTION FOR PASSWORD TOGGLE

  // SET USER INFO FUNCTION
  const handleSetUser = (event, inputType) => {
    setUser({ ...user, [inputType]: event.target.value })
  }
  // END OF SET USER INFO FUNCTION

  //  FUNCTION TO HANDLE USER LOGIN
  const handleLogin = async (e) => {
    e.preventDefault()
    toastId.current = toast('Please wait...', {
      autoClose: false,
      isLoading: true,
    })

    setLoading(true)

    try {
      await axios
        .post(`https://portalapi.lifeworthhmo.com/api/Login/Admin`, user)
        .then((res) => {
          toast.update(toastId.current, {
            render: 'Login succesful! Please wait while we redirect you.',
            type: 'success',
            autoClose: 2000,
            isLoading: false,
          })
          sessionStorage.setItem('fullName', res?.data?.fullName)
          sessionStorage.setItem('userId', res?.data?.userId)
          sessionStorage.setItem('role', res?.data?.role)

          // res?.data?.role && <Redirect to='/claims' />
          history.push('/dash')
        })
      // .then(() => {
      //   console.log('a')

      //   return
      // })
    } catch (error) {
      setLoading(false)
      console.log(error)
      // dispatch(loginFailure())
      toast.update(toastId.current, {
        type: 'error',
        autoClose: 3000,
        isLoading: false,
        render: `${
          error?.response?.data?.title ||
          error?.response?.data?.description ||
          error?.message ||
          'Something went wrong, please try again'
        }`,
      })
    }
  }

  //   END OF FUNCTION TO HANDLE USER LOGIN

  return (
    // <>
    //   <ToastContainer />
    //   <div className="loginWrapper">
    //     <div className="loginLeft">
    //       <div className="loginImgWrapper img1">
    //         <img src={Img1} alt="" className="loginImg" />
    //       </div>
    //       <div className="loginImgWrapper img2">
    //         <img src={Img2} alt="" className="loginImg" />
    //       </div>
    //       <div className="loginImgWrapper img3">
    //         <img src={Img3} alt="" className="loginImg" />
    //       </div>
    //     </div>
    //     <div className="loginRight">
    //       <div className="loginRightMain">
    //         <form className="loginForm" onSubmit={handleLogin}>
    //           <div className="loginDesc">
    //             <div className="loginDescMain">Log in</div>
    //             <div className="loginMainSub">
    //               Please enter your login credentials
    //             </div>
    //           </div>
    //           <div className="loginInputs">
    //             <label htmlFor="">Username</label>
    //             <input
    //               type="text"
    //               className="loginEmailInput loginInput"
    //               placeholder="John Doe"
    //               onChange={(e) => handleSetUser(e, "userId")}
    //               required
    //             />
    //             <label htmlFor="">Password</label>
    //             <div className="passwordWrapper">
    //               <input
    //                 type={showPassword ? "text" : "password"}
    //                 className="loginPasswordInput loginInput"
    //                 placeholder="Password"
    //                 onChange={(e) => handleSetUser(e, "password")}
    //                 required
    //               />
    //               <span
    //                 onClick={handlePasswordToggle}
    //                 className="passwordToggleIcon"
    //               >
    //                 {showPassword ? <BsEye /> : <BsFillEyeSlashFill />}
    //               </span>
    //             </div>
    //           </div>
    //           <button className="loginBtn" type={"submit"} disabled={loading}>
    //             Login
    //           </button>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
      <ToastContainer />

      <div className='loginWrapper'>
        <div className='mainContainer'>
          <div className='mainContainerLeft'>
            <img src={lifeworthLogo} alt='Logo' className='lifeWorthLogo' />
            <br />
            <br />
            <div className='introText'>Dear Admin, WELCOME</div>
          </div>
          <div className='mainContainerRight'>
            <form className='signInForm' onSubmit={handleLogin}>
              <div className='signInText'>
                {' '}
                <span>Sign in</span> to your account
              </div>
              <input
                type='text'
                className='formInput'
                placeholder='Username'
                onChange={(e) => handleSetUser(e, 'userId')}
                required
              />

              <div className='passwordWrapper'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className='formInput'
                  placeholder='Password'
                  onChange={(e) => handleSetUser(e, 'password')}
                  required
                />
                <span
                  onClick={handlePasswordToggle}
                  className='passwordToggleIcon'
                >
                  {showPassword ? <BsEye /> : <BsFillEyeSlashFill />}
                </span>
              </div>

              <button
                className='loginButton'
                type={'submit'}
                disabled={loading}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page
