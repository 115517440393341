export const ReloadReducer = (state, action) => {
  switch (action.type) {
    case 'RELOAD':
      return {
        reload: !action.payload,
        newAmount: state.newAmount,
        claimsListUniversal: state.claimsListUniversal,
      }
    case 'SET_AMOUNT':
      return {
        reload: state.reload,
        newAmount: action.payload,
        claimsListUniversal: state.claimsListUniversal,
      }
    case 'SET_CLAIMSLIST':
      return {
        reload: state.reload,
        newAmount: action.payload,
        claimsListUniversal: action.payload,
      }

    default:
      return state
  }
}
